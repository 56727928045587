@import "../../helpers/Colours.scss";

.date-range-picker {
    .home {
        .ant-picker {
            padding: 8px 6px;
            background-color: $element-background;
            border: none;
            box-shadow: 0 0 0 2px $orange_02;
            margin-top: 0px;
            border-bottom: none !important;
            border-radius: 8px;
        }

        .ant-picker-active-bar {
            background: $orange;
        }
    }

    .ant-picker {
        padding: 0px 0px 10px 0px;
        background-color: transparent;
        border: none;
        box-shadow: none;
        border-bottom: 1px solid;
        border-radius: 0;
        padding: 0px 0px 13px 0px;
        background-color: transparent;
        border: none;
        box-shadow: none;
        border-bottom: 1px solid $black_1;
        border-radius: 0;
        margin-top: 16px;
    }

    .ant-picker:hover {
        border-bottom: 1px solid $black;
    }

    .ant-picker-active-bar {
        background: transparent;
    }

    input {
        font-weight: 500;
        font-size: 10px;
        line-height: 17px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
            "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
    }

    .ant-picker-cell-inner::before {
        border: 1px solid $orange !important;
    }
}
