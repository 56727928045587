@import "../../helpers/Colours.scss";

.view-staff-container {
    margin: 0px 20px 0px 20px;

    .title {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 35px;
    }

    .row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @media (max-width: 600px) {
            .data {
                width: 100% !important;
                margin-bottom: 20px !important;
            }
        }

        .data {
            display: flex;
            flex-direction: column;
            width: 260px;
            margin-bottom: 30px;

            .header {
                font-weight: 500;
                font-size: 10px;
                line-height: 12px;
                text-transform: uppercase;
                opacity: 0.5;
                margin-bottom: 10px;
            }

            .body {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
            }
        }
    }

    .view-data {
        background-color: $uploader-background;
        padding: 20px 32px 28px 32px;

        .details {
            max-height: 210px;
            height: 210px;
            overflow-y: auto;
            margin-top: 10px;
        }

        .header {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
        }

        .data {
            border-bottom: 1px solid $search-drp-bottom-brd;
            padding-bottom: 10px;
            padding-top: 12px;
            .name {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
            }
        }
    }
}

@media (max-width: 768px) {
    .modal-container .view-view-staff-card {
        margin: 0px 20px;
    }
}
