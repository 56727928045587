@import "../../helpers/Colours.scss";

.login-container {


  .login-data {
    max-width: 555px;
    min-width: 320px;
    width: 100%;
    min-height: 400px;
    height: fit-content;
    background-color: $white;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 20px;

    .text-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 400px;
      width: 100%;
      height: 137px;
      background: rgba(237, 237, 237, 0.4);
      border-radius: 10px;
      margin: 40px 0px 20px 0px;

      .text-content-header {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: black;
        margin-bottom: 16px;
      }

      .text-content-text {
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.4)
      }

      span {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: $black;
      }
    }

    .inputs {
      min-width: 110px;
      max-width: 395px;
      width: 100%;
      margin-top: 20px;
    }

    .forget-password-footer {
      margin-top: 62px;
    }

    .email-sent-footer {
      margin-top: 33px;
    }

  }

  .forget-password {
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: $orange;
    margin: 0;
    padding: 0;
    display: inline;
  }

  .forget-password-container {
    width: 100%;
    text-align: right;
    min-width: 110px;
    max-width: 395px;
    margin: 20px 0 0 0;
    padding: 0;
  }

  .email-image {
    margin-top: 32px;
  }


  .back-to-login {
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: $primary-blue;
    cursor: pointer;
  }

  .back-to-login-text {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.4);
    margin: 30px 0px 0px 0px;
  }

  .css-igs3ac {
    text-align: left;
    position: absolute;
    inset: -5px 0px 0px;
    margin: 0px;
    padding: 0px 8px;
    pointer-events: none;
    border-radius: inherit;
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
    min-width: 0%;
    border-color: transparent;
  }

  .css-1x5jdmq {
    border: 0px;
    // border-bottom: 1px solid #888;
    box-sizing: content-box;
    background: none;
    height: 1.4375em;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0px;
    width: 17px;
    animation-name: mui-auto-fill-cancel;
    animation-duration: 10ms;
    margin: 0 10px;
    // padding: 0 12px !important;
    font-size: 30px;
  }

  .css-1v4ccyo:hover {
    border-color: transparent !important;
  }

  @media (max-width: 1024px) {
    .doctor-content {
      display: none;
    }

    .login-data {
      margin: 0px !important;
    }
  }

  // Responsive Chnages - Start -------------------
  @media (max-width: 1440px) {
    .login-data {
      .otp-content {
        .otp-form {
          margin-top: 10px;
        }
      }
    }
  }

  @media (max-width: 768px) {

    .login-data {
      .otp-content {
        .otp-form input {
          width: 30px !important;
        }
      }
    }
  }

  // Responsive Chnages - End -------------------

}