@import "../../helpers/Colours.scss";

.view-report-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0px 40px;

    .title {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
    }

    .tabs {
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .tag-report-view {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        .report-data-content {
            padding: 8px 15px;
            border-radius: 10px;
            border: 1px solid $black_05;
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 10px;

            .download-icon {
                cursor: pointer;
            }

            .report-name {
                display: flex;
                align-items: center;

                span {
                    margin-left: 8px;
                    font-size: 14px;
                    font-weight: 400;
                }
            }
        }
    }

    .report-view {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        .content {
            flex: 1;
            min-width: 540px;
            overflow-y: auto;
        }

        .row {
            display: flex;
            flex-wrap: wrap;

            .data-view {
                display: flex;
                flex-direction: column;
                margin-right: 29px;
                margin-bottom: 24px;

                .header {
                    width: 169px;
                    font-weight: 500;
                    font-size: 10px;
                    line-height: 12px;
                    opacity: 0.5;
                    text-transform: uppercase;
                }

                .data {
                    width: 169px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    margin-top: 4px;
                }

                .comment {
                    width: 650px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    margin-top: 4px;
                    text-align: justify;
                }
            }
        }

        .report-editor {
            .editor-content {
                cursor: pointer;
                display: flex;
                align-items: center;
                text-decoration: none;
            }

            .editor {
                cursor: pointer;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: $light-blue;
                margin-left: 12px;
            }
        }
    }

    .delete-report {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .content {
            width: 40vw;

            .header {
                text-align: center;
            }
        }
    }

    .uploaded-file-view {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow-y: auto;

        .uploaded-files-area {
            display: flex;
            flex: 1;
            flex-direction: column;
            overflow-y: auto;

            .file-doc {
                display: flex;
                flex-direction: column;
                flex: 1 1 auto;
                overflow-y: auto;

                .file-content {
                    display: flex;
                    justify-content: space-between;
                    border-bottom: 1px solid $uploader-border;
                    padding-bottom: 16px;
                    padding-top: 16px;
                    margin-right: 10px;

                    .file-data {
                        display: flex;
                        align-items: center;

                        .file-name {
                            cursor: pointer;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 17px;
                            margin-left: 13.5px;
                        }
                    }

                    .file-properties {
                        display: flex;
                        align-items: center;

                        span {
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 15px;
                            opacity: 0.5;
                            margin-right: 28px;
                        }

                        img {
                            cursor: pointer;
                            margin-right: 15px;
                            width: 15px;
                        }
                    }
                }
            }

            .file-img {
                display: flex;
                margin-top: 14px;
                max-width: 10in;
                overflow-y: auto;
                flex: 0 0 auto;

                .img-uploaded {
                    position: relative;
                    margin-right: 13px;
                    border: 1px solid $black_07;
                    border-radius: 10px;

                    img {
                        width: 84px;
                        height: 84px;
                        border-radius: 10px;
                    }

                    .delete-content {
                        cursor: pointer;
                        position: absolute;
                        top: 3px;
                        left: 55px;

                        img {
                            width: 16px;
                            height: 16px;
                        }
                    }

                    .download-content {
                        cursor: pointer;
                        position: absolute;
                        top: 3px;
                        right: 3px;

                        img {
                            width: 16px;
                            height: 16px;
                        }
                    }

                    .extra-tag {
                        width: 26px;
                        height: 26px;
                        background-color: $white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                    }
                }
            }
        }
    }

    .assign-view-container {
        flex: 1;
        display: flex;
        flex-direction: column;

        .content {
            flex: 1;

            .input-field {
                width: 280px;
            }

            @media (max-width: 626px) {
                .to-assign-data {
                    flex-direction: column;
                    align-items: flex-start !important;
                    margin-top: 10px;

                    .search-area {
                        width: 100%;
                    }

                    .input-field {
                        width: 100%;
                    }
                }
            }

            .assign-content {
                padding: 16px 30px 0px 30px;
                border-radius: 8px;
                background-color: $black_05;
                margin-bottom: 16px;

                .header {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                }

                .assign-data {
                    display: flex;
                    flex-direction: column;
                    padding-bottom: 16px;

                    .row-data {
                        display: flex;
                    }

                    .button-content {
                        display: flex;
                        justify-content: flex-end;

                        .button {
                            width: 100px;
                        }
                    }

                    .data {
                        display: flex;
                        flex-direction: column;
                        width: 170px;
                        margin-right: 20px;
                        margin-top: 16px;

                        .assign-header {
                            text-transform: uppercase;
                            font-weight: 500;
                            font-size: 10px;
                            line-height: 12px;
                            opacity: 0.5;
                        }

                        .assign-body {
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 17px;
                            margin-top: 5px;
                        }
                    }
                }

                .to-assign-data {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    padding-bottom: 16px;

                    .assign-mem {
                        width: 170px;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                        opacity: 0.5;
                    }

                    .search-area {
                        padding: 5px;
                        height: 50px;
                        border-radius: 5px;

                        .input-dropdown-container .input-dropdown-container .MuiInput-root {
                            padding: 0px !important;
                        }
                    }
                }
            }
        }
    }

    .view-report {
        flex: 1;
        display: flex;
        flex-direction: column;

        .loading-content {
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .document-view {
            display: flex;
            flex-direction: row;
            flex: 1;
        }
    }

    .audit-container {
        flex: 1;
        max-height: 400px;
        overflow-y: auto;

        .audit-row {
            display: flex;
            justify-content: space-between;
            padding: 12px;
            margin-right: 36px;
            border-bottom: 1px solid $black_07;

            label {
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 17px;
                opacity: 0.6;
            }

            .audit-data {
                font-weight: 500;
            }
        }
    }

    .requested-changes {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        min-width: 320px;
        padding: 16px 12px 16px 16px;
        border: 1px solid $black_06;
        border-radius: 10px;
        margin-left: 20px;

        .requested-changes-title {
            color: $black;
            font-family: Inter, sans-serif;
            font-size: 14px;
            font-weight: 700;
        }

        .requested-changes-view {
            margin-top: 16px;
            flex: 1;
            overflow-y: auto;

            .requested-changes-card-outline {
                border-bottom: 1px solid $black_03;
                padding: 20px 0px;
                margin-right: 5px;

                &:first-child {
                    padding: 0px 0px 20px 0px;
                }

                &:last-child {
                    padding: 20px 0px 0px 0px;
                }
            }

            .requested-changes-card {
                display: flex;
                flex-direction: column;
                background-color: $box-analytics-background;
                border-radius: 10px;
                padding: 10px;

                .changes-requested-header {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .user-name {
                        color: $black;
                        font-family: Inter, sans-serif;
                        font-size: 12px;
                        font-weight: 500;
                        opacity: 0.5;
                    }

                    .login-user {
                        color: $orange;
                        font-family: Inter, sans-serif;
                        font-size: 12px;
                        font-weight: 500;
                        background-color: $orange_02;
                        padding: 3px 10px;
                        border-radius: 8px;
                        border: 1px solid $orange;
                    }

                    span {
                        padding: 3px 11px;
                        color: $yellow;
                        text-align: center;
                        font-family: Inter, sans-serif;
                        font-size: 12px;
                        font-weight: 500;
                        background-color: $yellow-02;
                        border-radius: 8px;
                        border: 1px solid $yellow;
                    }
                }

                .duration {
                    color: $black;
                    font-family: Inter, sans-serif;
                    font-size: 12px;
                    font-weight: 400;
                    opacity: 0.3;
                    margin-left: 10px;
                    text-align: end;
                }

                .changes-completed-header {
                    margin-top: 5px;
                    color: $black_05;
                    font-family: Inter, sans-serif;
                    font-size: 10px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    span {
                        margin-left: 5px;
                    }
                }

                .changes-note {
                    margin: 10px 0px;
                    color: $black;
                    font-family: Inter, sans-serif;
                    width: 267px;
                    font-size: 12px;
                    font-weight: 500;
                    line-height: 24px;
                }
            }
        }

        .add-request {
            display: flex;
            align-items: center;
            margin-top: 10px;
            height: 40px;

            .add-request-comment {
                flex: 1 0;
                display: flex;

                textarea {
                    flex: 1;
                    resize: none;
                    border: none;
                    box-shadow: none;
                    outline: none;
                    font-family: Inter, sans-serif;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    &::placeholder {
                        color: $black_05;
                    }

                    &::-webkit-scrollbar {
                        display: none;
                    }
                }
            }

            img {
                cursor: pointer;
            }
        }
    }

    .footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 12px;
    }

    .footer-doc {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 10px;
    }
}

@media (max-width: 1024px) {
    .modal-container .view-report-card {
        width: max-content;
        margin: 0px 20px;
    }

    .view-report-container {
        .data-view {
            margin-right: 10px !important;

            // .header{
            //     width: 140px !important;
            // }

            // .data{
            //     width: 140px !important;
            // }
        }
    }
}

@media (max-width: 768px) {
    .view-report-container {
        padding: 0px 0px 0px 10px !important;
        overflow: auto !important;
    }

    .view-report-card {
        overflow: auto !important;

        .tab {
            min-width: fit-content;
        }

        .delete-report {
            .content {
                min-width: fit-content;
            }
        }
    }
}