.MuiBackdrop-root {
    pointer-events: none;
}

.cell {
    text-align: start !important;
}

@media (max-width: 1600px) {
    .modal-container {
        .add-report-card {
            height: 92vh !important;
        }
        .view-view-doctor-card {
            width: 670px !important;
            height: 650px !important;
        }
        .view-edit-doctor-card {
            width: 670px !important;
            height: 650px !important;
        }
    }

    .side-bar-container {
        width: 230px;
        .nav-link-container .nav-link .text {
            font-size: 14px;
        }
    }

    .css-15for3s-MuiTypography-root {
        font-size: 14px;
    }

    .editor-container .document-content .footer .report-btn .report-btn {
        font-size: 13px;
    }

    .btn {
        font-size: 13px;
    }

    .doc-button-container {
        height: 40px;
    }
}

@media (max-width: 1400px) {
    .editor-container {
        width: calc(100% - 250px);
    }

    .side-bar-container {
        .logo {
            width: 90px;
            height: 59px;
            margin-top: 20px;
        }
        .nav-link-container {
            margin-top: 10px;
            .nav-link {
                height: 32px;
                .text {
                    font-size: 12px;
                    flex: 0.7 1;
                }
                .nav-img {
                    width: 17px;
                    height: 17px;
                    flex: 0.3 1;
                }
            }
            .notification-link-wrapper {
                margin-top: 20px;
                height: 40px;

                .notification-img {
                    width: 17px;
                    height: 17px;
                }

                .notification-text {
                    font-size: 12px;
                    flex: 0.9 1;
                    .count {
                        width: 24px;
                        height: 24px;
                        margin-left: 10px;
                    }
                }
            }
        }
        .profile {
            padding: 10px 20px 0px 20px;
            .profile-text {
                font-size: 10px;
                padding-bottom: 8px;
            }
            .image-text .text {
                .name {
                    font-size: 12px;
                }
                .role {
                    font-size: 10px;
                }
            }
        }

        .logout {
            height: 35px;
            margin-top: 15px;
            .logout-img {
                width: 15px;
                height: 15px;
            }
            .logout-text {
                font-size: 12px;
            }
        }
    }

    .modal-container .add-report-card {
        width: 900px !important;
        min-height: 82vh !important;
        height: 772px !important;
        transform: scale(0.85);
        margin-top: 150px;
    }

    // .MuiModal-root.modal-container {
    //     overflow-y: auto;
    // }

    // .modal-container .view-report-card {
    //     width: 800px !important;
    //     // min-height: 80vh !important;
    //     // height: 620px;
    // }

    .view-report-container {
        .report-view .row .data-view {
            .header {
                font-size: 8px;
            }
            .data {
                font-size: 12px;
            }
        }
        .row .data {
            .header {
                font-size: 8px;
            }
            .body {
                font-size: 12px;
            }
        }
    }

    .settings-container .accordion-content .accordion-details-root .body .types:first-child {
        margin-top: 10px;
    }

    .templates-container .table .text {
        font-size: 12px;
    }

    .reports-container .table .data .data-row .cell {
        text-align: left;
    }

    .home-container {
        .header .text {
            font-weight: 500;
            font-size: 20px;
        }
        .assigned-targets .table-view {
            flex: 3 1;
            margin-right: 5px;

            .header {
                .title {
                    font-size: 12px;
                }
                .content .time-plan .plan {
                    font-size: 12px;
                }
            }
        }
        .body .title {
            font-size: 20px;
        }
        .assigned-targets .data-view .role-row .role-name {
            font-size: 12px;
        }
    }
    .tabs-container .tab {
        font-size: 12px;
    }

    .App {
        text-align: center;
        font-size: 12px;
    }

    .reports-container {
        .dot {
            width: 7px;
            height: 7px;
        }
        .top-bar {
            .text {
                font-size: 20px;
            }
            .indicator-box .indicator .text {
                font-size: 9px;
                margin-left: 15px;
            }
        }
        .table .text {
            font-size: 11px;
        }
        .icon {
            margin: 13px 12px 10px 16px;
            width: 18px;
            height: 19px;
        }
    }

    .search-container .input {
        font-size: 12px;
    }

    .doctors-container {
        .top-bar .text {
            font-size: 20px;
        }
        .table .text {
            font-size: 11px;
        }
    }

    .users-container .top-bar .text {
        font-size: 20px;
    }

    .templates-container {
        .top-bar .text {
            font-weight: 500;
            font-size: 20px;
        }
        .table .text {
            font-size: 11px;
        }
    }

    .analytics-container .top-bar .text {
        font-size: 20px;
    }

    :where(.css-dev-only-do-not-override-1km3mtt).ant-picker .ant-picker-input > input:placeholder-shown {
        text-overflow: ellipsis;
        font-size: 10px;
        margin-top: 7px;
    }

    .doc-button-container {
        height: 34px;
    }

    .img {
        height: 15px;
        overflow-clip-margin: content-box;
        overflow: clip;
    }

    .analytics-container {
        .top-header .download-content {
            margin-top: 3px;

            .data .report {
                font-size: 13px;
            }
        }

        .user-analytics .card-content {
            height: 36px;

            .details {
                .header {
                    font-size: 9px;
                }

                .body {
                    font-size: 13px;
                }
            }
        }
        .table .header-text {
            font-size: 12px;
        }
    }

    .settings-container .top-bar .text {
        font-size: 20px;
    }

    .settings-container .tag-area .edit-access {
        font-size: 12px;
    }

    .settings-container .account-container .header .title {
        font-size: 12px;
    }

    .settings-container .account-container .header .header-text {
        font-size: 12px;
    }

    .settings-container .account-container .image-content .user-data .header {
        font-size: 14px;
    }

    .settings-container .account-container .image-content .user-data .detail {
        font-size: 9px;
    }

    .settings-container .account-container .personal-data .header {
        font-size: 11px;
    }

    .input-container .input .font {
        font-size: 14px;
    }

    .input-container .label {
        font-size: 9px !important;
    }

    .settings-container .accordion-content .accordion-summary-root .accordion-summary-content .text {
        font-size: 12px;
    }

    .img {
        height: 8px;
    }

    .notification-container .header .title {
        font-size: 20px;
    }

    .settings-container .accordion-content .accordion-details-root .header span {
        font-size: 12px;
    }

    .settings-container .accordion-content .accordion-details-root .body .types span {
        font-size: 12px;
    }

    .settings-container .accordion-content .according-footer .cancel-button .button-label {
        font-size: 12px;
    }

    .settings-container .accordion-content .according-footer .cancel-button .button-label {
        font-size: 12px;
        line-height: 17px;
    }

    .doc-button-container {
        font-size: 12px;
    }

    .templates-container .table .data .data-row .cell .icon {
        cursor: pointer;
        width: 15px;
    }

    .analytics-container .user-analytics .card-content .details .body {
        font-size: 14px;
    }

    .modal-container .view-view-doctor-card {
        width: 670px !important;
        height: 600px !important;
    }

    .editor-container .document-content .editor-area {
        width: 90%;
    }

    .editor-container .document-content {
        margin: 25px -46px 0px 40px;
        // flex: 4  !important;
    }

    .editor-container .other-data .according-content {
        width: 290px;
    }

    .editor-container .document-content .footer {
        width: 91%;
    }
}

@media (max-width: 1366px) {
    .modal-container .view-view-doctor-card {
        width: 670px !important;
        height: 550px !important;
    }

    .view-doctor-container .view-data .details {
        max-height: 90px;
    }

    .modal-container .view-edit-doctor-card {
        height: 550px !important;
    }

    .view-edit-doctor-container .view-data .details {
        max-height: 110px;
    }

    element.style {
        height: 56px;
    }

    .rich-text-container {
        width: 93%;
    }

    .editor-container .other-data .according-content {
        width: 260px;
    }

    .css-15for3s-MuiTypography-root {
        font-size: 13px;
    }

    .css-6ii4g2-MuiButtonBase-root-MuiAccordionSummary-root .MuiTypography-root {
        font-size: 13px;
    }

    .editor-container .document-content .editor-area .editable .editor-content .col-data .content {
        max-height: 20vh;
    }

    .doc-button-container {
        margin-bottom: 10px;
    }
}

@media (max-width: 1300px) {
    .side-bar-container {
        width: 200px;
        flex: 0 0 auto;
    }

    .home-container {
        // width: calc(100vw - 230px) !important;
        // margin: 30px 15px 0px 15px;
    }

    .date-range-picker .ant-picker {
        margin-right: 11px;
    }

    // .settings-container .body-content {
    //     width: 663px;

    // }
    .css-1ff8729-MuiInputBase-root-MuiFilledInput-root {
        width: 133px;
    }

    .modal-container .view-view-doctor-card {
        height: 300px !important;
    }

    .modal-container .view-edit-doctor-card {
        height: 350px !important;
    }
}

@media (max-width: 1280px) {
    .modal-container .view-view-doctor-card {
        height: 550px !important;
    }

    .modal-container .view-edit-doctor-card {
        height: 600px !important;
    }

    .view-edit-doctor-container .footer {
        margin-top: 68px;
    }

    .view-edit-doctor-container .view-data .details {
        max-height: 110px;
    }

    .add-doctor-container .clinic-name .row-data {
        max-height: 100px;
    }

    .editor-container .document-content {
        margin: 25px -105px 0px 40px;
    }
}

.home-container .body .MuiAccordionSummary-expandIconWrapper {
    right: 12px;
    position: relative;
}
.css-yw020d-MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    right: 0px !important;
}
.home-container .body .data-row-header {
    padding: 0 16px 0 0;
}
.home-container .body .small {
    width: calc(100% / 4);
    justify-content: center;
}
.reports-container .table .text {
    text-align: left;
}
.indicator-box {
    margin-left: auto;
}
.indicator-box .indicator {
    margin-right: 20px;
}
.indicator-box .indicator .margin-right {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
    color: #000000 !important;
    z-index: 1;
    font-size: 10px !important;
    font-weight: 500 !important;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
        "Droid Sans", "Helvetica Neue", sans-serif;
    top: 10;
    left: 13;
}
.view-report-container .uploaded-file-view .uploaded-files-area .file-img .img-uploaded img {
    object-fit: cover;
}
.layout-container {
    width: calc(100vw - 5px);
    max-width: 100vw;
}
.text-container {
    flex: 0 0 auto;
    max-width: calc(100% - 250px);
    width: 100%;
}

.text-container > div {
    margin: 0px !important;
    padding: 35px 35px 20px 35px;
    height: calc(100% - 55px);
}
.home-container .assigned-targets .data-view .role-list {
    display: block;
}
.home-container .assigned-targets .data-view .role-list .check-role {
    display: inline-block;
}
.home-container .assigned-targets {
    max-height: 380px;
    min-height: 350px;
    height: auto;
}
.reports-container .top-bar,
.doctors-container .top-bar,
.users-container .top-bar,
.templates-container .top-bar {
    padding: 0px;
}

.reports-container .table .header .medium,
.reports-container .data .data-row .medium {
    width: 12%;
}
.reports-container .table .header .small,
.reports-container .data .data-row .small {
    width: 6%;
}
.reports-container .table .header .large,
.reports-container .data .data-row .large {
    width: 18%;
}
.css-t0gywj-MuiPaper-root-MuiAccordion-root:before {
    background-color: transparent;
}

.modal-container .add-report-card {
    margin-top: 0px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
}
.modal-container .view-add-clinic-card {
}
.add-report-container .fill-form .form {
    margin: 0px 0px 0px 0px;
    padding: 40px 40px 0px 40px;
}
.css-1ff8729-MuiInputBase-root-MuiFilledInput-root {
    width: auto;
}
.add-report-container .stepper,
.edit-report-container .stepper {
    margin: 35px 40px 0px 40px;
}
.add-report-container .add-media .uploaded-files-area .file-img .img-uploaded img {
    object-fit: cover;
}
.stepper-container {
    justify-content: center;
}
.modal-container .view-report-card {
    height: fit-content;
    min-height: 80vh !important;
}
.view-report-container {
    margin: 0px 40px 0px 40px;
    height: calc(100% - 40px);

    .report-view {
        position: static;
        height: 100%;
        display: flex;
        flex-direction: column;

        .footer {
            margin-top: auto;
            justify-content: flex-end;
            align-items: flex-end;
            display: flex;

            .footer-btn {
                position: static;
                top: 0px;
                right: 0px;
                display: flex;
                justify-content: flex-end;

                .doc-button-container {
                    width: fit-content;
                }
            }
        }
    }
    .audit-container {
        max-height: 100%;
        .audit-row {
            margin-right: 0px;
        }
    }
    .assign-view-container {
        margin-right: 0px;

        .footer-btn .button {
            width: fit-content;
        }
    }
    .uploaded-file-view {
        margin-right: 0px;
    }
}

.view-report-container,
.add-clinic-container,
.view-user-card-container,
.add-user-container,
.more-acces-container,
.add-report-type-container,
.edit-report-type-container {
    margin: 0px 0px 0px 0px;
    padding: 0px 40px 0px 40px;
}
.notification-container .body .content .notification-content {
    .data .message {
        width: fit-content;
    }
    .other-data .time {
        text-align: end;
        padding-right: 50px;
    }
}

.settings-container {
    .body-content {
        margin: 0px;
    }
    .account-container {
        .header {
            margin-bottom: 20px;
        }
        .personal-data .forms .row {
            display: inline-flex;
            width: 100%;
            justify-content: flex-start;
            align-items: flex-start;

            .input-area {
                display: inline-flex;

                .input-container {
                    min-width: 215px !important;
                    width: 100%;
                }
            }
        }
    }

    .accordion-content .accordion-root {
        border-radius: 5px;
        min-height: 55px;
    }
}

.css-1elwnq4-MuiPaper-root-MuiAccordion-root:before,
.css-t0gywj-MuiPaper-root-MuiAccordion-root:before {
    background-color: transparent !important;
}
.analytics-container {
    .table.home-analytics-table {
        margin-bottom: 0px;
        margin-top: 20px;
    }
    .top-header .content .button-content {
        display: flex;
        align-items: center;
    }
    .table .data {
        max-height: calc(100vh - 413px);
    }
}
.notification-container .body {
    padding: 40px 60px 0px;

    .content {
        height: calc(100vh - 158px);

        .notification-content {
            padding: 15px 20px;

            .data .profile-img {
                height: 35px;
                width: 35px;
            }
        }
    }
}

@media (max-width: 1600px) {
    .text-container {
        max-width: calc(100vw - 220px);
    }
    .templates-container .table .text,
    .reports-container .table .text,
    .doctors-container .table .text {
        font-size: 13px;
    }
    .tabs-container .tab {
        font-size: 12px;
    }
    .indicator-box .indicator .indicator-text {
        font-size: 10px;
    }
    .delete-doc-container {
        .body {
            font-size: 12px;
        }
        .header {
            font-size: 22px;
        }
    }
    .doc-button-container {
        padding: 0 25px;
        height: 35px;
    }
    .view-report-container .audit-container .audit-row label {
        font-size: 12px;
    }
    .view-report-container .assign-view-container .content .assign-content .assign-data .data .assign-body {
        font-size: 12px;
    }
    .view-report-container .report-view .row .data-view .data {
        font-size: 12px;
    }
    .view-report-container .uploaded-file-view .uploaded-files-area .file-doc .file-content .file-data .file-name {
        font-size: 12px;
    }
    .view-clinic-container .view-data .data .name {
        font-size: 12px;
    }
    .new-button-container {
        font-size: 12px;
    }
    .search-container .input {
        font-size: 12px;
    }

    .summary-box-container .blue-box .minimized-box-cell {
        .text {
            font-size: 15px;
        }
        .info-container {
            .pending {
                font-size: 9px;
            }
            .time {
                font-size: 14px;
            }
        }
        .scribe-image-container .icon {
            width: 18px;
            height: 18px;
        }
        .row .cell {
            .value {
                font-size: 16px;
            }
            .text {
                font-size: 9px;
            }
        }
    }

    // navbar--------
    .side-bar-container {
        width: 220px;

        .logo {
            width: 100px;
            height: auto;
        }
        .nav-link-container {
            .nav-link {
                height: 40px;

                .text {
                    font-size: 12px;
                }
                .nav-img {
                    width: 22px;
                    height: 22px;
                }
            }
            .notification-link-wrapper {
                width: calc(100% - 20px);
                padding-right: 0px;
                .notification-img {
                    width: 20px;
                    height: 20px;
                }
                .notification-text {
                    font-size: 12px;
                }
            }
        }
        .profile .image-text {
            .text {
                .role {
                    font-size: 10px;
                }
                .name {
                    font-size: 12px;
                }
            }
            .nav-img {
                width: 40px;
                height: 40px;
                font-size: 16px;
            }
        }
        .logout {
            .logout-img {
                width: 18px;
                height: 18px;
            }
            .logout-text {
                font-size: 12px;
            }
        }
    }

    .reports-container .table .data .data-row .cell .urgency-box {
        width: 90px;
        height: 30px;

        .urgency-icon {
            width: 16px;
            height: 16px;
        }
    }
}
@media (max-width: 1440px) {
    .login-container {
        .login-body {
            height: 85vh;
            scale: 90%;
        }
        .login-data {
            .text-content {
                margin: 20px 0px 10px 0px;
            }

            .otp-content {
                margin-top: 10px;

                .send-otp {
                    margin: 35px 0px 10px 0px;
                }
            }
        }
    }
    .analytics-container .user-analytics .card-content {
        padding: 18px 0px 18px 20px;
    }
    .templates-container .table .text,
    .reports-container .table .text,
    .doctors-container .table .text {
        font-size: 12px;
    }
}
@media (max-width: 1366px) {
    .text-container {
        flex: 0 0 auto;
        max-width: calc(100vw - 200px);
        width: 100%;
    }
    .text-container > div {
        padding: 30px 15px 20px 15px;
        height: calc(100% - 50px);
    }
    .side-bar-container {
        width: 200px;
    }
    .reports-container .table .header {
        width: 1134px;
    }
    .reports-container .table .data-row {
        width: 1134px;
    }

    .reports-container .table .data {
        overflow-x: hidden;
        overflow-y: scroll;
        width: fit-content;
    }
}
@media (max-width: 1280px) {
    .side-bar-container .logo {
        margin-top: 17px;
        margin-left: 17px;
        width: 95px;
    }
    .text-container {
        flex: 0 0 auto;
        max-width: calc(100vw - 195px);
        width: 100%;
    }
    .side-bar-container {
        width: 190px;
    }
    .side-bar-container .nav-link-container .notification-link-wrapper {
        width: 160px;
    }
    .side-bar-container .nav-link-container .nav-link .nav-img {
        width: 18px;
        height: 18px;
    }
    .side-bar-container .nav-link-container .nav-link {
        height: 38px;
    }
    .home-container .body .title {
        font-size: 18px;
    }
    .home-container .body .according-content .name {
        font-size: 13px;
    }
    .home-container .body .accordionDetails .data-row {
        padding: 9px 0px;
    }
    .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root {
        min-height: 45px;
    }
    .css-sh22l5-MuiButtonBase-root-MuiAccordionSummary-root.Mui-expanded {
        min-height: 50px;
    }
    .css-15v22id-MuiAccordionDetails-root {
        padding: 0px 16px 6px !important;
    }
    .reports-container .table .data .data-row .cell .urgency-box .urgency-icon {
        width: 14px;
        height: 14px;
    }
    .reports-container .table .data .data-row .cell .urgency-box {
        width: 85px;
        height: 28px;
    }
    .tabs-container .tab {
        padding: 8px 18px;
    }
    .add-report-container .title,
    .edit-report-container .title,
    .view-clinic-container .title,
    .view-edit-clinic-container .title,
    .more-acces-container .title,
    .add-user-container .title {
        font-size: 23px;
    }

    .add-report-container .title,
    .edit-report-container .title {
        margin: 0px 20px;
    }

    .stepper-container .step-wrapper .step .int {
        width: 45px;
        height: 45px;
    }
    .add-report-container .fill-form .form,
    .edit-report-container .fill-form .form {
        padding: 20px 20px 0px 20px;
        margin: 0px;
    }
    .search-container {
        height: 35px;
        width: 180px;
    }
    .search-container .icon {
        margin: auto 12px;
    }
    .add-report-container .fill-form .footer-btn,
    .add-report-container .assign-to .footer-btn,
    .add-report-container .add-media .footer-btn,
    .edit-report-container .fill-form .footer-btn,
    .edit-report-container .assign-to .footer-btn,
    .edit-report-container .add-media .footer-btn {
        margin: 10px 20px 0px 20px;
    }
    .add-report-container .add-media .file-uploader,
    .edit-report-container .add-media .file-uploader {
        margin: 20px 20px 0px 20px;
    }
    .add-report-container .add-media .uploaded-files-area,
    .edit-report-container .add-media .uploaded-files-area {
        margin: 10px 20px 0px 20px;
    }
    .add-report-container .assign-to .form,
    .edit-report-container .assign-to .form {
        margin: 40px 20px 0px 20px;
    }
    .add-report-container .stepper,
    .edit-report-container .stepper {
        margin: 30px 20px 0px 20px;
    }
    .css-1jy569b-MuiFormLabel-root-MuiInputLabel-root {
        margin-left: -14px;
        margin-top: 8px;
    }
    .css-khjf82-MuiFormControl-root-MuiTextField-root .MuiInputBase-input {
        font-size: 14px !important;
    }
    .view-report-container,
    .add-clinic-container,
    .view-user-card-container,
    .add-user-container,
    .more-acces-container,
    .add-report-type-container,
    .edit-report-type-container {
        padding: 0px 20px 0px 20px;
    }

    .summary-box-container .blue-box .maximized-box-cell {
        padding: 0px 30px;
    }
    .users-container .table .data .profile-size {
        width: 40px;
        height: 40px;
    }
    .notification-container .body {
        padding: 30px 30px 0px;
    }
    .notification-container .body .content {
        height: 100%;
    }
    .notification-container .body .content .notification-content {
        width: fit-content;
        min-width: 93%;
        padding: 15px 25px;
    }
    .notification-container .body .content .notification-content .data .message {
        font-size: 14px;
    }
    .notification-container .body .content .notification-content .data .profile-img {
        height: 32px;
        width: 32px;
    }
    .analytics-container .top-header .content {
        padding: 10px 20px 10px 25px;
    }
    .analytics-container .top-header .download-content {
        margin: 0px;
        padding: 10px 25px 10px 20px;
    }
    .analytics-container .top-header .download-content .data .report {
        font-size: 12px;
    }
    .analytics-container .top-header .download-content img {
        width: 38px;
        height: 38px;
    }
    .analytics-container .top-header .doc-button-container {
        margin: 0px;
    }
    .analytics-container .top-header .button-content {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .input-dropdown-container .input-dropdown-container .MuiFormLabel-root {
        font-size: 11px;
    }
    .analytics-container .table .data .data-row-analysis .cell {
        font-size: 12px;
    }
    .analytics-container .table .data .data-row-analysis {
        padding: 15px 0px 15px 0px;
    }
    .analytics-container .table {
        margin-top: 10px;
    }
    .settings-container .body-content {
        padding: 20px 25px 20px 25px;
    }
}

@media (max-width: 768px) {
    .text-container {
        max-width: calc(100% - 60px) !important;
    }

    .modal-container .view-add-clinic-card {
        width: 360px !important;
    }
    .modal-container .view-edit-clinic-card {
        width: 360px !important;
    }
}
