@import "../../helpers/Colours.scss";

.tags-row-box {
    display: flex;
    align-items: center;
    flex-direction: row;

    .add-more {
        cursor: pointer;
        display: flex;
        justify-content: center;
        margin: 0px 10px 0 0;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        color: $tool-tip-tow-allocation;

        img {
            height: 18px;
            width: 18px;
            margin-right: 10px;
        }
    }
}

.tag-body {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $black_60;
    margin-right: 10px;
    font-weight: 500;
    font-size: 14px;
    color: $white;
    border-radius: 5px;
    padding: 5px 10px;

    img {
        height: 16px;
        width: 16px;
        margin-left: 10px;
    }
}

.more-acces-container {
    padding: 0px 30px;

    .title {
        display: flex;
        width: fit-content;
        align-items: center;
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        padding: 5px 0px;
    }

    .content {
        padding: 30px 0px;

        .lable {
            padding: 10px 0px 40px 0px;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
        }

        .tag-content {
            display: flex;
            justify-content: flex-start;
            margin-top: 32px;
        }
    }

    .buttons {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        align-items: center;

        .button {
            font-weight: 600;
            font-size: 14px;
            cursor: pointer;
        }

        .save-container {
            width: 265px;
            margin: 0 0 0 32px;
        }
    }
}

@media (max-width: 768px) {
    .modal-container .more-access-view {
        margin: 0px 20px;
    }
}
