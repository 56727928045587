@import "../../helpers/Colours.scss";

.error-container {
    background-image: url("../../assets/images/ErrorPage.svg");
    background-repeat: no-repeat;
    background-size: cover;
    display: flex;
    flex-direction: column;
    width: 100vw;
    height: 100vh;
    background-position: center center;

    .empty {
        flex: 1;
    }

    .button-container {
        flex: 0.3;
        display: flex;
        justify-content: center;
    }
}