.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

    /* width */
    ::-webkit-scrollbar {
      width: 5px;
      height: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
      background: #f1f1f1;
      opacity: 0.5;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
      background: #888;
      opacity: 0.5;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
      background: #555;
      opacity: 0.5;
  }

  .rpv-core__page-layer {
    height: 0px !important;
  }

  .rpv-core__text-layer {
      border: 1px solid black;
  }

  .rpv-core__inner-pages.rpv-core__inner-pages--vertical::-webkit-scrollbar {
    display: none;
  }