@import "../../helpers/Colours.scss";

.summary-box-container {
    .minimize {
        height: 72px;
        align-items: center;
        padding: 0px 18px;
    }

    .maximize {
        height: 212px;
        padding: 18px;
    }

    .blue-box {
        background: $primary-blue;
        border-radius: 16px;
        display: flex;
        flex-direction: row;
        flex: 1;
        margin: 24px 0px 30px 0px;
        transition: 0.3s;

        .show-border {
            border-left: 2px solid $pending-text-colour;
            border-right: 2px solid $pending-text-colour;
        }

        .minimized-box-cell {
            flex: 1;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-around;

            .image-box {
                display: flex;
                align-items: center;
            }

            .scribe-image-container {
                width: 32px;
                height: 32px;
                border-radius: 10px;
                background: $scribing-icon-background;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 12px;

                .icon {
                    width: 20px;
                    height: 20px;
                }
            }

            .text {
                font-size: 16px;
                font-weight: 600;
                color: $tertiary-blue;
            }

            .selected {
                color: white;
            }

            .info-container {
                display: flex;
                flex-direction: column;

                .pending {
                    font-size: 10px;
                    font-weight: 700;
                    color: $pending-text-colour;
                    text-align: left;
                }

                .time {
                    font-size: 16px;
                    font-weight: 600;
                    color: $white;
                }
            }
        }

        .maximized-box-cell {
            display: flex;
            flex-direction: column;
            flex: 1;
            padding: 0px 40px;
            align-items: center;

            .heading {
                display: flex;
                align-items: center;

                .scribe-image-container {
                    width: 32px;
                    height: 32px;
                    border-radius: 10px;
                    background: $scribing-icon-background;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    margin-right: 12px;

                    .icon {
                        width: 20px;
                        height: 20px;
                    }
                }

                .text {
                    font-size: 16px;
                    font-weight: 600;
                    color: $tertiary-blue;
                }

                .selected {
                    color: white;
                }

            }

            .row {
                display: flex;
                flex: 1;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;

                .margin-right {
                    margin-right: 50px;
                }

                .cell {
                    display: flex;
                    flex-direction: column;

                    .text {
                        font-size: 10px;
                        font-weight: 700;
                        color: $pending-text-colour;
                        text-align: left;
                    }

                    .value {
                        font-size: 20px;
                        font-weight: 600;
                        color: $white;
                    }
                }
            }
        }

        .arrow {
            height: 32px;
            min-width: 32px;
            min-height: 32px;
            background: rgba(255, 255, 255, 0.2);
            border-radius: 8px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;

            .icon {
                width: 16px;
                height: 16px;
            }
        }
    }

    .focus {
        cursor: pointer;
    }
}

// Responsive Chnages - Start -------------------

@media (max-width: 1024px) {
    .summary-box-container {
        .minimized-box-cell {
            .text {
                font-size: 14px !important;
            }

            .time {
                font-size: 14px !important;
            }

            .pending {
                font-size: 8px !important;
            }
        }

        .maximized-box-cell {
            padding: 0px 10px !important;

            .heading {
                .text {
                    font-size: 14px !important;
                }
            }

            .cell {
                .value {
                    font-size: 14px !important;
                }
            }

            .margin-right {
                margin-right: 2.5vw !important;
            }
        }

        .arrow {
            height: 24px !important;
            min-width: 24px !important;
            min-height: 24px !important;
        }
    }
}

@media (max-width: 860px) {
    .summary-box-container {
        .minimized-box-cell {
            .image-box {
                .scribe-image-container {
                    display: none;
                }
            }
        }
    }
}

@media(max-width: 768px) {
    .summary-box-container .blue-box>div {
        flex-direction: column !important;
        justify-content: center !important;
        text-align: center;

        span,
        div {
            text-align: center !important;
        }
    }

    .summary-box-container {
        .maximize {
            height: 150px;

        }

        .blue-box .maximized-box-cell .row {
            width: 100%;
            padding: 0px 10px;
            flex-direction: column;
            align-items: start;
            justify-content: flex-start;

            .cell {
                flex-direction: row;
                justify-content: space-between;
                margin: 0px !important;
                gap: 5px;
                width: 100%;
                align-items: center;

                .text {
                    text-align: left !important;
                }
            }
        }
    }
}

@media(max-width: 550px) {
    .summary-box-container {
        .maximize {
            flex-direction: column;
            height: fit-content;
            gap: 20px;
        }
    }
}

@media (max-width: 425px) {
    .summary-box-container {
        display: none !important;
    }

    .table-container {
        margin-top: 30px;
    }
}

// Responsive Chnages - End -------------------