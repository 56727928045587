@import "../../helpers/Colours.scss";

.delete-doc-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    margin: 0px;
    padding: 0px;

    .trash-icon {
        width: 99.79px;
        height: 100px;
    }

    .content {
        display: flex;
        flex-direction: column;
        align-items: center;

        label {
            margin: 10px;
        }
    }

    .header {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
    }

    .body {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        opacity: 0.6;
        text-align: center;
    }

    .footer {
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .cancel {
            cursor: pointer;
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            opacity: 0.6;
            margin-right: 30px;
        }
    }
}