@import "../../helpers/Colours.scss";

.tool-tip-one {

    .wrapper-error{
        display: flex;
        align-items: center;
        justify-content: center;

        .img{
            margin: 0px 5px;
        }
    }
    .label {
        height: 25px;
        background: $black;
        width: 65px;
        border-radius: 2px;
        font-size: 12px;
        font-weight: 400;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .arrow::before {
        background: $black;
        height: 13px;
        width: 10px;
        border-radius: 1px;
    }
}

.tool-tip-two {
    .wrapper-error{
        display: flex;
        align-items: center;
        justify-content: center;

        .img{
            margin: 0px 5px;
        }

     
    }
    .label {
        height: 83px;
        background: $tool-tip-two-background;
        width: 150px;
        border-radius: 5px;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .arrow::before {
        background: $tool-tip-two-background;
        height: 13px;
        width: 10px;
        border-radius: 1px;
    }

    .toolTip {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding: 10px;

        .name {
            font-size: 14px;
            font-weight: 500;
        }

        .allocation {
            font-size: 10px;
            font-weight: 500;
            margin-top: 10px;
            margin-bottom: 5px;
            color: $tool-tip-tow-allocation;
        }

        .date {
            font-size: 14px;
            font-weight: 400;
        }
    }
}

.tool-tip-three {
    .wrapper-error{
        display: flex;
        align-items: center;
        justify-content: center;

        .img{
            margin: 0px 5px;
        }
    }
    .label {
        height: 39px;
        background: $black;
        width: 136px;
        border-radius: 2px;
        padding: 0px;
        display: flex;
        align-items: center;
    }

    .MuiTooltip-arrow
    {
        left: -60px !important;
    }
    .arrow::before {
        background: $black;
        height: 4px;
        width: 10px;
        border-radius: 1px;
    }
}