@import "../../helpers/Colours.scss";

.add-referee-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0px 20px 0px 20px;

    .header-section {
        display: flex;
        align-items: flex-start;

        .header {
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
            margin-bottom: 35px;
        }
    }

    .body {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        .footer {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 35px;

            .cancel-btn {
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: $black_50;
                margin-right: 32px;
                cursor: pointer;
            }

            .button {
                width: 160px;
            }
        }
    }
}

@media (max-width: 768px) {
    .modal-container .add-referrer-card {
        margin: 0px 20px;
    }
}
