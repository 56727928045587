@import "../../helpers/Colours.scss";

.reset-password-container {
  background-image: url("../../assets/images/LoginPage.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;


  .login-data {
    width: 555px;
    height: 659px;
    background-color: $white;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .text-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 395px;
      height: 137px;
      background: rgba(237, 237, 237, 0.4);
      border-radius: 10px;
      margin: 40px 0px 20px 0px;

      .text-content-header {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: black;
      }

      span {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: $black;
      }
    }

    .inputs {
      width: 395px;
      margin-top: 20px;
    }

    .footer {
      display: flex;
      width: 395px;
      margin-top: 33px;
      align-items: center;
      justify-content: space-between;

      .check-box {
        display: flex;
        align-items: center;

        .checkbox {
          margin-right: 12px;
          width: 20px;
          height: 20px;
          background-color: #fafafa;
          border: 1px solid #cacece;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
          padding: 9px;
          border-radius: 4px;
          display: inline-block;
          position: relative;
        }
      }

      .forgot-label {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: $orange;
        cursor: pointer;
      }

      .footer {
        display: flex;
        width: 395px;
        align-items: center;
        justify-content: space-between;
        margin-top: 33px;

        .check-box {
          display: flex;
          align-items: center;

          .checkbox {
            margin: 0px !important;
            background-color: #fafafa;
            border: 1px solid #cacece;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
            padding: 9px;
            border-radius: 3px;
            display: inline-block;
            position: relative;
          }
        }
      }

      .remember {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        opacity: 0.4;
        margin-left: 12px;
      }
    }

    .forget-password {
      cursor: pointer;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: $orange;
    }

    .otp-content {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      align-items: center;

      .title {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
      }

      .message {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        margin-top: 10px;
        opacity: 0.5;
      }

      .email {
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        opacity: 0.5;
      }

      .otp-form {
        display: flex;
        flex-direction: row;
        margin-top: 40px;
        justify-content: center;

        .otp-input {
          display: flex;
          .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
            font-weight: 500;
            font-size: 30px;
            line-height: 36px;
            width: 17px;
            padding: 0px 12px !important;
            border-bottom: 1px solid $otp-bottom;
            margin: 0px 10px;
          }
        }
      }
      .button {
        width: 395px;
      }
      .send-otp {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: $orange;
        margin: 41px 0px 20px 0px;
        cursor: pointer;
      }
    }
  }

  .button-content {
    margin-top: 42px;
    width: 395px;
  }
}
