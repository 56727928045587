$primary-blue: #203fad;
$secondary-blue: #f8f9fb;
$tertiary-blue: #22e5da;
$light-blue: #2da9ef;
$dark-blue: #2d4cef;
$white: #ffffff;
$black: #000000;
$red: #dd3a3a;
$yellow: #ef8a2d;
$yellow-02: rgba(255, 210, 95, 0.2);
$green: #28d764;
$orange: #eb6d26;

$orange_02: #eb6d261a;
$hover: #eaedf2;
$element-background: #f5f5f5;
$inactive-tab-background: #0000000d;
$inactive-tab-colour: #00000099;
$error-background-colour: #ededed66;
$border-colour: #eff2f966;
$border-bottom: #999898;
$table-text-colour: #666666;
$progress-bar-background: #203fad33;
$side-bar-notification-border-colour: #6666661c;
$side-bar-profile: #eff2f9;
$urgency-background-red: #dd3a3a0d;
$urgency-background-yellow: #ef8a2d0d;
$urgency-background-light-blue: #2da9ef0d;
$urgency-background-dark-blue: #2d4cef0d;
$indicator-text-colour: #00000080;
$scribing-icon-background: #22e5da33;
$pending-text-colour: #ffffff80;
$not-assigned-border: #203fad1a;
$search-text: #00000080;
$tool-tip-two-background: #2d3036;
$tool-tip-tow-allocation: #b4b4b4;
$table-border: #eff2f966;
$blue-hover: #eff2f9;
$blue-tag: #203fad;
$light-blue-2: rgba(32, 63, 173, 0.15);
$skyBlue-2: #2da9ef1a;
$skyBlue-1: #2da9ef33;
$search-drp-bottom-brd: #e7ebf2;
$date-label: rgba(0, 0, 0, 0.87);
$uploader-border: #d9d9d9;
$uploader-background: #f8f8f8;
$uploader-border: rgba(0, 0, 0, 0.08);
$search-background: #f5f5f5;
$audio-clip-background: #3c3f44;
$blue-active: rgba(32, 63, 173, 0.05);
$orange_02: rgba(221, 58, 58, 1);
$blue_02: rgba(45, 123, 239, 1);
$black_07: rgba(0, 0, 0, 0.07);
$black_06: rgba(0, 0, 0, 0.06);
$black_06: rgba(0, 0, 0, 0.05);
$black_05: rgba(0, 0, 0, 0.5);
$black_03: rgba(0, 0, 0, 0.03);
$black_02: rgba(0, 0, 0, 0.02);
$black_01: rgba(0, 0, 0, 0.01);
$black_28: rgba(0, 0, 0, 0.28);
$black_2: rgba(0, 0, 0, 0.2);
$black_05: rgba(0, 0, 0, 0.05);
$black_1: rgba(0, 0, 0, 0.1);
$whight_50: #ffffff80;
$black_60: rgba(0, 0, 0, 0.6);
$black_15: rgba(0, 0, 0, 0.15);
$whight_10: #ffffff1a;
$login-doctor-background: rgba(255, 255, 255, 10%);
$black_50: rgba(0, 0, 0, 0.5);
$otp-bottom: #888888;
$home-assign: #f9f9fb;
$user-role: #203fad;
$box-analytics-background: #f9f9fb;
$orange_01: rgba(221, 58, 58, 0.1);

