@import "../../helpers/Colours.scss";

.edit-select-template-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0px 20px;
    position: relative;
    height: 0px;

    .header {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
    }

    .form-area {
        display: flex;
        flex-direction: column;
        margin-top: 24px;

        .report-type {
            margin-right: 10px;
        }

        .report-con {
            margin-right: 10px;
        }

        .input-template-drp {
            position: relative;

            img {
                position: absolute;
                top: 15px;
                right: 4px;
            }
        }

        .report-type-row {
            flex: 1;
            display: flex;
            margin-bottom: 35px;
        }
    }

    .editor-body {
        max-height: 58%;
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    .footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 16px;

        .cancel-button {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            margin-right: 32px;
            opacity: 0.5;
            cursor: pointer;
        }

        .button {
            width: 180px;
        }
    }
}

@media (min-height: 800px) {
    .editor-body {
        max-height: 65% !important;
    }
}
