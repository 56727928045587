@import "../../helpers/Colours.scss";

.settings-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 35px 40px;

    .input-field {
        width: 20vw;
    }

    .input-field + .input-field {
        margin-left: 16px;
    }

    .top-bar {
        display: flex;

        .text {
            font-weight: 500;
            font-size: 24px;
        }
    }

    .user-manual {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .title {
            font-size: 16px;
            font-weight: 500;
            margin: 20px 0px;
        }

        .card-content {
            display: flex;

            .card {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                border-radius: 10px;
                border: 1px solid $light-blue-2;
                background: $home-assign;
                box-shadow: 0px 0px 4px 0px $uploader-border;
                margin-right: 10px;
                min-width: 160px;
                min-height: 138px;

                .button {
                    cursor: pointer;
                    display: flex;
                    align-items: center;
                    margin-top: 10px;
                    text-decoration: none;
                    border: 0px;
                    background: transparent;

                    .header {
                        color: $orange;
                        font-size: 12px;
                        font-weight: 600;
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    .tag-area {
        display: flex;
        justify-content: space-between;
        margin: 20px 0px;
        align-items: center;

        .edit-access {
            cursor: pointer;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: $orange;
            text-wrap: nowrap;
        }
    }

    .settings-contetnt-area {
        height: calc(100vh - 165px);
        overflow-y: auto;

        .body-content {
            background-color: $home-assign;
            padding: 20px 40px 20px 40px;
            border-radius: 10px;
            margin: 26px 0px;
            box-shadow: 0px 0px 4px $black_07;
        }

        .account-container {
            // flex: 1;
            display: flex;
            flex-direction: column;

            .header {
                display: flex;
                flex-direction: column;
                align-items: self-start;

                .title {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                }

                .header-text {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    opacity: 0.28;
                    margin-top: 8px;
                }
            }

            .image-content {
                position: relative;
                display: flex;
                margin: 1 0px 0px 20px 0px;

                .user-img {
                    width: 80px;
                    height: 80px;
                    font-size: 24px;
                    border-radius: 50%;
                }

                .camera-background {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    position: absolute;
                    cursor: pointer;
                    width: 32px;
                    height: 32px;
                    background-color: $white;
                    left: 50px;
                    opacity: 0.9;
                }

                .camera-img {
                    cursor: pointer;
                }

                .user-data {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    margin-left: 28px;

                    .header {
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        margin-bottom: 10px;
                        color: $black_50;
                    }

                    .detail {
                        font-weight: 500;
                        font-size: 10px;
                        line-height: 12px;
                        color: $black_50;
                        text-transform: uppercase;
                    }
                }
            }

            .personal-data {
                display: flex;
                flex-direction: column;

                .header {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                }

                .forms {
                    margin-top: 12px;
                    padding: 24px;
                    border-radius: 10px;
                    background-color: $white;

                    .row {
                        display: flex;
                        margin-top: 20px;
                        // flex-wrap: wrap;

                        .empty-input {
                            min-width: 260px !important;
                        }

                        .input-area {
                            margin-right: 20px;
                            min-width: 240px;
                            margin-top: 12px;
                        }

                        @media (max-width: 1024px) {
                            .input-area {
                                width: 100%;
                            }
                        }
                    }
                }
            }

            .footer {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin-top: 20px;

                .cancel-button {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    margin-right: 32px;
                    opacity: 0.5;
                    cursor: pointer;
                }

                .button {
                    width: 164px;
                }
            }
        }

        .account-notification-container {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .disabled {
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                opacity: 0.2;
            }
        }

        .team-notification-container {
            flex: 1;
            display: flex;
            flex-direction: column;
            justify-content: center;

            .disabled {
                font-weight: 600;
                font-size: 20px;
                line-height: 24px;
                opacity: 0.2;
            }
        }
    }
    .accordion-content {
        display: flex;
        flex-direction: column;
        margin: 32px 0px 0px 0px;

        .accordion-root {
            min-height: 64px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-bottom: 0.5rem;
            box-shadow: $uploader-border;

            .icon-transform {
                transform: rotate(0deg);
            }
        }

        .accordion-summary-root {
            padding: 0px;
            transition: 1s;
            margin: 0px 32px 0px 32px;

            .accordion-summary-content {
                margin: 0px;

                .text {
                    font-weight: 500;
                    font-size: 16px;
                    line-height: 19px;
                    color: $black;
                }
            }
        }

        .accordion-details-root {
            padding: 12px 32px;

            .header {
                display: flex;
                justify-content: space-between;

                span {
                    font-weight: 700;
                    font-size: 14px;
                    line-height: 17px;
                }

                .types-header {
                    span {
                        cursor: pointer;
                        margin-right: 36px;
                        opacity: 0.8;
                    }
                }

                .types-header:last-child {
                    margin-right: 55px;
                }
            }

            .body {
                .types {
                    margin: 28px 0px 0px 0 px;
                    box-shadow: inset 0px -1px 0px $black_02;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;

                    span {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                    }

                    .boxes {
                        .MuiCheckbox-root {
                            margin-right: 20px;
                        }

                        .Mui-checked {
                            border-radius: 6px;
                        }

                        .MuiButtonBase-root:last-child {
                            margin-right: 75px;
                        }
                    }
                }

                .types:first-child {
                    margin-top: 20px;
                }

                .types:last-child {
                    margin-bottom: 30px;
                }
            }
        }

        .according-footer {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin-top: 30px;

            .cancel-button {
                margin-right: 30px;

                .button-label {
                    cursor: pointer !important;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    color: $black_50;
                }
            }
        }
    }
}

// Responsive Chnages - Start -------------------

@media (min-width: 1920px) {
    .settings-container .account-container .personal-data {
        display: flex;
        justify-content: space-between;
        gap: 25px;
    }
}

@media (max-width: 1024px) {
    .settings-container .settings-contetnt-area {
        height: calc(100vh - 150px);
        padding-right: 3px;
    }
}

@media (max-width: 1024px) {
    .settings-container {
        .input-field {
            width: 100%;
            margin-bottom: 10px;
        }
    }
}

@media (max-width: 768px) {
    .settings-container {
        .settings-contetnt-area {
            .account-container .personal-data .forms {
                .header {
                    margin-bottom: 10px;
                }
                .row {
                    flex-direction: column !important;
                    margin: 0px;

                    .input-container + .input-container {
                        margin: 20px 0px 0px 0px;
                    }
                }
            }
            .body-content {
                padding: 20px 20px 20px 20px;
            }
        }
        .user-manual {
            display: none;
        }

        .input-field + .input-field {
            margin-left: 0px;
        }
    }
}
@media (max-width: 425px) {
    .settings-container {
        .tag-area .edit-access {
            display: none;
        }
        .user-manual .card-content {
            flex-direction: column;
            width: 100%;

            .card {
                width: 100%;
                margin: 0px !important;
            }
            .card + .card {
                margin-top: 10px !important;
            }
        }
    }
}

// Responsive Chnages - End -------------------
