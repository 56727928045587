@import "../../helpers/Colours.scss";

.doctors-container {
    flex: 1;
    margin-top: 35px;
    display: flex;
    flex-direction: column;

    .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .top-bar {
        display: flex;
        flex-direction: column;
        padding: 0px 40px;

        .text {
            font-weight: 500;
            font-size: 24px;
        }
    }

    .doctor-table-container {
        padding-top: 32px;
        flex: 1;

        .table {
            display: flex;
            flex-direction: column;
            padding-top: 40px;
            height: calc(100vh - 200px);
            overflow-x: auto;

            .text {
                font-weight: 500;
                font-size: 14px;
            }
            @media (max-width: 1440px) {
                .text {
                    font-size: 12px;
                }
            }

            .header {
                border-bottom: groove;
                border-bottom-width: 1px;
                border-bottom-color: $border-colour;
                padding: 0px 0px 13px 0px;
                display: flex;
                min-width: 800px;

                .header-row {
                    display: flex;
                    align-items: center;
                    flex: 1;

                    .icon {
                        margin-left: 8px;
                        cursor: pointer;
                    }

                    .filter-content {
                        display: flex;
                        flex: 1;
                        justify-content: flex-end;

                        .filter-icon {
                            cursor: pointer;
                            width: 18px;
                            opacity: 0.3;
                        }
                    }
                }
            }

            .data {
                display: flex;
                flex-direction: column;
                overflow-y: auto;
                min-width: 800px;

                .data-row {
                    display: flex;
                    flex-direction: row;
                    color: $table-text-colour;
                    margin: 0px 0px;
                    border-bottom: groove;
                    border-bottom-width: 1px;
                    border-bottom-color: $border-colour;

                    .cell {
                        text-wrap: nowrap;
                        flex: 1;
                        display: flex;
                        padding: 16px 0px;
                        justify-content: space-between;

                        .icon-container {
                            .icon-edit {
                                cursor: pointer;
                                opacity: 0.6;
                            }

                            .icon {
                                cursor: pointer;
                            }
                            img {
                                width: 20px;
                            }

                            .space {
                                padding-left: 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    .cursor {
        cursor: pointer;
    }


}

  // Responsive Chnages - Start -------------------
 
  @media(max-width: 1024px){
    .doctors-container .doctor-table-container .table{
        height: calc(100vh - 190px);
    }

    
}
@media(max-width: 425px){
    .doctors-container .doctor-table-container .table{
        height: calc(100vh - 222px);

        .data .data-row .cell .icon-container{
            display: none;
        }
    }

    
}

  // Responsive Chnages - End -------------------
