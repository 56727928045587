@import "../../helpers/Colours.scss";

.side-bar-container {
    width: 250px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: $secondary-blue;
    position: sticky;
    top: 0;
    overflow-y: auto;

    .logo-small {
        display: none;
    }

    .logo {
        width: 107px;
        height: 70px;
        margin-top: 30px;
        margin-left: 20px;
    }

    .nav-link-container {
        margin-top: 48px;

        .nav-link {
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: $black;
            margin: 0px 20px 5px 20px;

            .nav-img {
                width: 25px;
                height: 25px;
                flex: 0.4;
            }

            &.active {
                background: $hover;
                border-radius: 10px;
            }

            &:hover {
                background: $hover;
                border-radius: 10px;
                cursor: pointer;
            }

            .text {
                font-weight: 500;
                font-size: 14px;
                flex: 0.6;
                text-align: left;

                &.active {
                    color: $primary-blue;
                }

                &:hover {
                    color: $primary-blue;
                }
            }
        }

        .notification-link-wrapper {
            margin-top: 35px;
            background: $border-colour;
            cursor: pointer;
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            color: $black;
            padding: 0px 20px;
            border-top: groove;
            border-top-width: 1px;
            border-bottom: groove;
            border-bottom-width: 1px;
            border-color: $border-colour;

            .notification-img {
                width: 25px;
                height: 25px;
                flex: 0.4;
            }

            .notification-text {
                flex: 0.6;
                text-align: left;
                font-weight: 500;
                font-size: 14px;
                display: flex;
                align-items: center;

                .count {
                    width: 26px;
                    height: 26px;
                    background: $orange;
                    border-radius: 6px;
                    display: flex;
                    margin-left: 12px;
                    align-items: center;
                    justify-content: center;
                    color: $white;
                }
            }
        }
    }

    .profile {
        padding: 30px 20px 0px 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: $side-bar-profile;
        height: 100%;

        .profile-text {
            font-size: 10px;
            font-weight: 600;
            color: $black;
            padding-bottom: 16px;
        }

        .image-text {
            display: flex;

            .nav-text {
                text-decoration: none;
            }

            .nav-img {
                width: 45px;
                height: 45px;
                border-radius: 50%;
            }

            .text {
                display: flex;
                flex-direction: column;
                margin-left: 12px;
                justify-content: center;

                .name {
                    font-size: 14px;
                    font-weight: 500;
                    color: $black;
                    text-align: left;
                }

                .role {
                    font-size: 12px;
                    font-weight: 600;
                    color: $primary-blue;
                    text-align: left;
                }
            }
        }
    }

    .logout {
        text-decoration: none;
        width: 100%;
        border-width: 0px;
        height: 45px;
        border-radius: 8px;
        background: $white;
        display: flex;
        align-items: center;
        margin-top: 24px;
        justify-content: center;

        .logout-img {
            width: 20px;
            height: 20px;
            flex: 0.4;
        }

        .logout-text {
            font-size: 14px;
            font-weight: 600;
            color: $black;
            flex: 0.6;
            text-align: left;
        }
    }
}

@media (max-width: 768px) {
    .side-bar-container {
        width: 60px !important;

        .logo {
            display: none;
        }

        .logo-small {
            height: clamp(30px, 5vw, 40px);
            display: block;
            margin-top: 30px;
        }

        .nav-link-container {
            .nav-link {
                margin: 5px 5px !important;
                .text {
                    display: none;
                }
            }

            .notification-link-wrapper {
                width: 60px !important;
                height: fit-content !important;
                flex-direction: column;
                padding: 10px 0px !important;

                .notification-text {
                    .text {
                        display: none;
                    }
                    .count {
                        margin-top: 10px;
                        margin-left: 0px !important;
                    }
                }
            }
        }

        .profile {
            padding: 0px !important;

            .profile-text {
                display: none;
            }

            .image-text {
                width: 60px;
                display: flex;
                align-items: center;
                flex-direction: column !important;
                padding: 10px 0px;

                .text {
                    margin-left: 0px;
                    margin-top: 5px;
                    .name {
                        display: none;
                    }
                }
            }

            .logout {
                width: fit-content !important;
                padding: 5px 10px;
                margin-left: auto;
                margin-right: auto;
                margin-top: 5px;
                .logout-text {
                    display: none;
                }
            }
        }
    }
}
