@import "../../helpers/Colours.scss";

.home-container {
    flex: 1;
    margin: 35px 35px 0px 35px;

    .header {
        display: flex;
        align-items: center;
        // flex: 1;
        margin-bottom: 26px;

        .text {
            font-weight: 500;
            font-size: 24px;
        }

        .elements {
            display: flex;
            flex: 1;
            justify-content: flex-end;
            align-items: center;
        }
    }

    .assigned-targets {
        display: flex;
        height: 350px;
        border: 1px solid $black_06;
        box-shadow: 0px 2px 8px $black_02;
        border-radius: 10px;

        ::-webkit-scrollbar {
            width: 0px;
            height: 1px;
        }

        .table-view {
            display: flex;
            flex-direction: column;
            flex: 4;
            border-radius: 10px 0px 0px 10px;
            padding: 10px;
            overflow-x: auto;

            .chart-content {
                flex: 1;
                display: flex;
                flex-direction: column;
                justify-content: end;
                height: 78%;
                overflow-x: auto;
                // min-width: 580px;
            }

            .header {
                display: flex;
                justify-content: space-between;
                padding: 0px 10px;

                .title {
                    font-weight: 600;
                    font-size: 14px;
                    padding-bottom: 6px;
                    line-height: 17px;
                }

                .content {
                    display: flex;

                    .time-plan {
                        display: flex;
                        margin-right: 10px;

                        .plan {
                            padding-bottom: 6px;
                            display: flex;
                            align-items: center;
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 17px;
                            color: $black_28;
                            margin-left: 30px;
                            cursor: pointer;
                        }

                        .plan-active {
                            color: $yellow !important;
                            border-bottom: 1px solid $yellow;
                        }
                    }
                }
            }

            .area-chart {
                display: flex;
                flex-direction: column;
                justify-content: flex-end;
                height: 78%;
            }
        }

        .data-view {
            background-color: $home-assign;
            flex: 2;
            border-left: 1px solid $black_06;
            border-radius: 0px 10px 10px 0px;
            padding: 10px;

            .tabs {
                margin-bottom: 15px;
            }

            .role-row {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 10px 0px;
                margin: 0px 12px 0px 12px;
                box-shadow: inset 0px -1px 0px rgba(180, 180, 180, 0.21);

                .role-name {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                    opacity: 0.6;
                }
            }

            .list-user {
                max-height: 220px;
                height: 220px;
                overflow-y: auto;
                padding-bottom: 10px;
                border-bottom: 1px solid $black_06;

                .level-row {
                    cursor: pointer;
                    display: flex;
                    justify-content: space-between;
                    padding: 12px;
                    align-items: center;
                    margin-top: 5px;

                    .user-name {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        opacity: 0.6;
                    }

                    .level {
                        font-weight: 700;
                        font-size: 10px;
                        line-height: px;
                        opacity: 0.4;
                    }
                }

                .level-row-active {
                    background: $blue-active;
                    border-radius: 8px;
                    .user-name {
                        color: $blue-tag;
                        opacity: 1 !important;
                    }
                }

                .active-level {
                    background: $blue-active !important;
                    border-radius: 8px !important;
                    .user-name {
                        color: $blue-tag !important;
                        opacity: 1 !important;
                    }
                }
            }

            .role-list {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin: 15px 10px 0px 5px;

                .check-role {
                    display: flex;
                    align-items: center;

                    .title {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 15px;
                        opacity: 0.8;
                    }
                }
            }
        }
    }

    .body {
        display: flex;
        flex-direction: column;
        margin-top: 45px;
        // margin-bottom: 45px;
        max-height: calc(100vh - 510px);

        .large1 {
            // flex: 1;
            width: 41vw;
        }
        .large2 {
            // flex: 1;
            width: 40vw;
        }
        .large3 {
            // flex: 1;
            width: 38.7vw;
        }

        .medium {
            flex: 0.6;
        }

        .small {
            // flex: 0.4;
            width: 120px;
        }

        .data-row-header {
            flex: 1;
            display: flex;
        }

        .header-data {
            display: flex;
            align-items: center;
            justify-content: space-between;

            flex: 1;

            .header-row {
                display: flex;
                align-items: center;
            }
        }

        .title {
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
        }

        .data {
            flex: 1;
            display: flex;
            margin-right: 5px;
        }

        .according-content {
            margin-top: 24px;
            overflow-y: auto;
            
            > div{
                margin-right: 5px;
                margin-top: 5px;
            
                + div{
                    margin-top: 10px;
                }
            }
            > div:last-child{
                margin-bottom: 10px;
            }

            .acording-header {
                flex: 1;
                display: flex;
                justify-content: space-between;
            }

            .name {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                opacity: 0.8;
            }
        }

        .accordionDetails {
            max-height: 30vh;
            overflow-y: auto;
            margin: 10px 0px;
            background-color: $home-assign;
            border-radius: 10px;
            padding: 5px 20px;
            box-shadow: 0px 2px 8px $black_02;

            .data-row {
                padding: 15px 0px;
            }
        }

        .MuiAccordionSummary-expandIconWrapper {
            width: 0px;
        }
    }

    .custom-tooltip {
        background-color: $tool-tip-two-background;
        border: 1px solid $search-drp-bottom-brd;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        padding: 0px 10px 5px 5px;

        .tooltip-header {
            .label-value {
                font-weight: 500;
                font-size: 14px;
            }
        }

        .label-value {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-weight: 600;
            font-size: 12px;
            color: $white;
            margin-left: 5px;
            opacity: 0.7;
        }

        .label-role {
            margin-left: 5px;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: $white;
            opacity: 0.7;
        }
    }
}

@media (max-width: 768px) {
    .home-container {
        // max-width: calc(100% - 60px);
    }
}

  // Responsive Chnages - Start -------------------
  @media(max-width: 1024px){
    .home-container .body{
        max-height: calc(100vh - 490px);
    }
  }
@media (max-width: 768px) {
    .home-container .body{
        max-height: calc(100vh - 500px);
    }
}


  // Responsive Chnages - End -------------------

