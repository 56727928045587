@import "../../helpers/Colours.scss";

.modal-container {
  display: flex;
  justify-content: center;
  align-items: center;

  .card {
    max-height: 100vh;
    margin: 20px 0px;
    width: 670px;
    height: 772px;
    background: $white;
    border-radius: 16px;
    outline: 0;
    padding: 20px;
    display: flex;
    flex-direction: column;

    .close {
      display: flex;
      justify-content: flex-end;
      align-items: center;

      .img {
        width: 24px;
        height: 24px;
        cursor: pointer;
      }

      .edit {
        width: 20px;
        height: 20px;
        cursor: pointer;
        margin-right: 16px;
      }
    }
  }

  .add-report-card {
    width: 900px !important;
    height: 88vh !important;
  }

  .add-referrer-card {
    width: 500px !important;
    height: 225px !important;
  }

  .view-referrer-card {
    width: 500px !important;
    height: 18vh !important;
  }

  .card-delete {
    width: 440px;
    height: 335px;
  }

  .edit-user-card {
    width: 670px !important;
    height: 568px !important;
  }

  .add-user-card {
    width: 670px !important;
    height: 560px !important;
  }

  .card-view-user {
    width: 440px !important;
    height: 329px !important;
  }

  .card-select-template {
    width: 52rem !important;
    height: 88vh !important;
  }

  .more-access-view {
    width: 560px;
    height: 290px;
  }

  .view-add-report-type-card {
    width: 560px !important;
    height: 365px !important;
  }

  .view-report-card {
    width: 1150px !important;
    height: 80vh !important;
  }

  .view-template-card {
    width: 900px !important;
    height: 80vh !important;
  }

  .view-add-clinic-card {
    width: 670px !important;
    height: auto;
  }

  .view-add-doctor-card {
    width: 670px !important;
    height: auto;
  }

  .view-add-staff-mem-card {
    width: 670px !important;
    height: auto;
  }

  .view-view-clinic-card {
    width: 670px !important;
    height: 540px !important;
  }

  .view-view-doctor-card {
    width: 670px !important;
    height: 680px !important;
  }

  .view-view-staff-card {
    width: 670px !important;
    height: auto;
  }

  .view-edit-clinic-card {
    width: 670px !important;
    height: auto;
  }

  .view-edit-doctor-card {
    width: 670px !important;
    height: 680px !important;
  }
}
