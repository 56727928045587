.domain-container {
    display: flex;
    flex-direction: column;

    .file-uploader {
        margin: 20px 0px 0px 0px !important;
    }

    .uploaded-files-area {
        margin: 20px 0px 0px 0px !important;
    }
}

.domain-container-loader {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
