@import "../../helpers/Colours.scss";

.login-container {
  background-image: url("../../assets/images/LoginPage.svg");
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .login-body {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  .footer-text {
    padding-bottom: 10px;
    color: $white;

    .privacy {
      color: $white;
    }
  }

  .login-data {
    max-width: 555px;
    min-width: 320px;
    width: 100%;
    min-height: 400px;
    height: fit-content;
    background-color: $white;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 40px 20px;

    .text-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      max-width: 400px;
      width: 100%;
      height: 137px;
      background: rgba(237, 237, 237, 0.4);
      border-radius: 10px;
      margin: 40px 0px 20px 0px;

      .text-content-header {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: black;
      }

      span {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        color: $black;
      }
    }

    .inputs {
      min-width: 110px;
      max-width: 395px;
      width: 100%;
      margin-top: 20px;
    }

    .footer {
      display: flex;
      width: 395px;
      margin-top: 33px;
      align-items: center;
      justify-content: space-between;

      .check-box {
        display: flex;
        align-items: center;

        .checkbox {
          margin-right: 12px;
          width: 20px;
          height: 20px;
          background-color: #fafafa;
          border: 1px solid #cacece;
          box-shadow:
            0 1px 2px rgba(0, 0, 0, 0.05),
            inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
          padding: 9px;
          border-radius: 4px;
          display: inline-block;
          position: relative;
        }
      }

      .forgot-label {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: $orange;
        cursor: pointer;
      }

      .footer {
        display: flex;
        width: 395px;
        align-items: center;
        justify-content: space-between;
        margin-top: 33px;

        .check-box {
          display: flex;
          align-items: center;

          .checkbox {
            margin: 0px !important;
            background-color: #fafafa;
            border: 1px solid #cacece;
            box-shadow:
              0 1px 2px rgba(0, 0, 0, 0.05),
              inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
            padding: 9px;
            border-radius: 3px;
            display: inline-block;
            position: relative;
          }
        }
      }

      .remember {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        opacity: 0.4;
        margin-left: 12px;
      }
    }

    .forget-password {
      cursor: pointer;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: $orange;
    }

    .otp-content {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
      align-items: center;

      .title {
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
      }

      .message {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        margin-top: 10px;
        opacity: 0.5;
      }

      .email {
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        opacity: 0.5;
      }

      .otp-form {
        display: flex;
        flex-direction: row;
        margin-top: 40px;
        justify-content: center;

        input{
          border: none;
          border-bottom: 1px solid $otp-bottom;
          width: 40px !important;
          height: 36px;
          font-size: 30px;
          font-weight: 500;
        }
        input + input{
          margin-left: 8px;
        }

        input:focus-visible{
          outline: none;
          border-bottom: 1px solid $otp-bottom !important;
        }

      }

      .button {
        width: 395px;
      }

      .send-otp {
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        color: $orange;
        margin: 41px 0px 20px 0px;
        cursor: pointer;
      }
    }
  }

  .button-content {
    margin-top: 42px;
    max-width: 395px;
    min-width: 110px;
    width: 100%;
  }

  .css-igs3ac {
    text-align: left;
    position: absolute;
    inset: -5px 0px 0px;
    margin: 0px;
    padding: 0px 8px;
    pointer-events: none;
    border-radius: inherit;
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
    min-width: 0%;
    border-color: transparent;
  }

  .css-1x5jdmq {
    border: 0px;
    // border-bottom: 1px solid #888;
    box-sizing: content-box;
    background: none;
    height: 1.4375em;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0px;
    width: 17px;
    animation-name: mui-auto-fill-cancel;
    animation-duration: 10ms;
    margin: 0 10px;
    // padding: 0 12px !important;
    font-size: 30px;
  }

  .css-1v4ccyo:hover {
    border-color: transparent !important;
  }

  @media (max-width: 1024px) {
    .doctor-content {
      display: none;
    }

    .login-data {
      margin: 0px !important;
    }
  }

  // Responsive Chnages - Start -------------------
  @media (max-width: 1440px){
    .login-data{
      .otp-content{
        .otp-form{
          margin-top: 10px;
        }
      }
    } 
  }
  @media (max-width: 768px) {

    .login-data{
      .otp-content{
        .otp-form input{
          width: 30px !important;
        }
      }
    } 
  }
  // Responsive Chnages - End -------------------

}
