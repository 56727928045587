@import "../../helpers/Colours.scss";

.new-button-container {
    min-width: 100px;
    color: $orange;
    font-size: 14px;
    font-weight: 600;
    height: 16px;
    cursor: pointer;
    text-wrap: nowrap;
    margin-left: 10px;
}
