@import "../../helpers/Colours.scss";

.edit-user-container {
    padding: 0px 40px 0px 40px;
    flex: 1;

    .title {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 32px;
    }

    .file-uploader {
        padding: 3vh 0vh 0px 0px;
    }

    .add-user-content {
        .form-content {
            .row {
                display: flex;
                justify-content: space-between;
                margin-top: 10px;

                .input-drop-down {
                    height: 50px;
                }
            }
        }
        .add-type-content {
            margin-top: 10px;

            .title {
                font-weight: 500;
                font-size: 10px;
                line-height: 12px;
                color: $black_50;
            }

            .content {
                margin-top: 10px;
            }
        }

        .password-content {
            margin-top: 25px;
            .title {
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: $black_50;
            }
            .row {
                display: flex;
                justify-content: space-between;
                margin-top: 10px;
            }
        }
    }

    .footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 25px;

        .button {
            width: 170px;
        }

        .cancel-container {
            cursor: pointer;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: $black_50;
            margin-right: 30px;
        }
    }
}
