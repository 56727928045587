@import "../../helpers/Colours.scss";

.vertical-chart-container {
    position: relative;
    display: flex;
    flex: 1;

    .border {
        position: absolute;
        width: 100%;
        border-bottom: 1px dashed $black_15;
        height: 1px;
        bottom: 20px;
    }

    .chart-box {
        display: flex;
        flex-direction: column;
        min-width: 40px;
        padding: 0px 4px;

        .chart-body {
            display: flex;
            justify-content: center;
            flex-direction: row;
            width: 4.2vw;
            min-width: 40px;
            height: 100%;
            flex: 1;
            align-items: flex-end;

            .custom-width {
                width: 10px;
            }

            .scribe {
                background: linear-gradient(180deg, #203fad 0%, rgba(32, 63, 173, 0.1) 189.77%);
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
            }

            .proof {
                background: linear-gradient(180deg, #eb6d26 0%, rgba(235, 109, 38, 0.17) 199.77%);
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
            }

            .review {
                background: linear-gradient(
                    180deg,
                    #28d764 0%,
                    rgba(40, 215, 100, 0.15) 185.67%,
                    rgba(40, 215, 100, 0.449571) 185.67%
                );
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
            }

            .completed {
                background: linear-gradient(
                    180deg,
                    #dd3a3a 0%,
                    rgba(221, 58, 58, 0.361232) 152.43%,
                    rgba(221, 58, 58, 0.08) 173.24%
                );
                border-top-right-radius: 10px;
                border-top-left-radius: 10px;
            }
        }

        span {
            font-weight: 500;
            font-size: 10px;
            line-height: 12px;
            color: $black_50;
            text-transform: uppercase;
            margin-top: 8px;
        }
    }

    .chart-bodyx:last-child {
        .chart-body {
            padding-right: 0px;
            background-color: pink;
        }
    }

    .chart-body:first-child {
        .chart-body {
            padding-left: 10px;
        }
    }
}
