@import "../../helpers/Colours.scss";

.add-report-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .title {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        margin: 0px 20px;
    }

    .stepper {
        margin: 35px 0px 0px 40px;
    }

    .header {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
    }

    .input-field {
        width: 260px;
        margin-bottom: 20px;
    }

    .tag-report-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        .stepper {
            margin-bottom: 30px;
        }

        .report-content {
            flex: 1;
            overflow-y: auto;
            display: flex;
            flex-direction: column;
            padding: 0px 40px 0px 40px;

            .input-field {
                width: 260px;
                margin-bottom: 24px;
            }

            .report-view {
                flex: 1;
                display: flex;
                flex-direction: column;
                overflow-y: auto;

                .report-empty {
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-size: 14px;
                    font-weight: 400;
                    color: $black_60;
                }

                .report-data {
                    display: flex;
                    justify-content: center;
                    margin-bottom: 10px;

                    .MuiButtonBase-root {
                        padding: 0px !important;
                    }
                    .report-data-content {
                        flex: 1;
                        padding: 8px 15px;
                        margin-left: 10px;
                        border-radius: 10px;
                        border: 1px solid $black_05;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;

                        .download-icon {
                            cursor: pointer;
                        }

                        .report-name {
                            display: flex;
                            align-items: center;
                            span {
                                margin-left: 8px;
                                font-size: 14px;
                                font-weight: 400;
                            }
                        }
                    }
                }
            }
        }

        .footer-btn {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 0px 40px 0px 40px;

            .button {
                width: 100px;
            }

            .back {
                cursor: pointer;
                margin-right: 23px;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                opacity: 0.5;
            }
        }

        @media (max-width: 465px) {
            .report-content {
                .input-field {
                    width: 99%;
                }
            }
        }
        @media (max-width: 1280px) {
            .footer-btn,
            .report-content {
                padding: 0px 20px 0px 20px;
            }
        }
    }

    .fill-form {
        flex: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow-y: auto;
        .form {
            overflow-y: auto;
            .row {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }

            @media (max-width: 890px) {
                .input-field {
                    width: 40vw !important;
                }
            }

            @media (max-width: 650px) {
                .input-field {
                    width: 100vw !important;
                }
            }
        }

        .footer-btn {
            display: flex;
            justify-content: flex-end;
            margin: 10px 40px 0px 40px;

            .button {
                width: 100px;
            }
        }

        .report-editor {
            cursor: pointer;
            display: flex;
            align-items: center;
            position: absolute;
            top: 69.5vh;
            left: 38px;

            .editor {
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: $light-blue;
                margin-left: 12px;
            }
        }
    }

    .add-media {
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: center;
        overflow-y: auto;

        .file-uploader {
            margin: 20px 40px 0px 40px;
        }

        .uploaded-files-area {
            flex: 1;
            margin: 10px 40px 0px 40px;

            .file-doc {
                height: 120px;
                overflow-y: auto;

                .file-content {
                    display: flex;
                    justify-content: space-between;
                    border-bottom: 1px solid $uploader-border;
                    padding-bottom: 16px;
                    padding-top: 16px;
                    margin-right: 10px;

                    .file-data {
                        display: flex;
                        align-items: center;

                        .file-name {
                            cursor: pointer;
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 17px;
                            margin-left: 13.5px;
                        }
                    }

                    .file-properties {
                        display: flex;
                        align-items: center;

                        span {
                            font-weight: 500;
                            font-size: 12px;
                            line-height: 15px;
                            opacity: 0.5;
                            margin-left: 40px;
                        }

                        img {
                            cursor: pointer;
                            margin-right: 10px;
                            margin-left: 40px;
                            width: 15px;
                        }
                    }
                }
            }

            .file-img {
                display: flex;
                margin-top: 14px;
                max-width: 10in;
                overflow-y: auto;

                .img-uploaded {
                    position: relative;
                    margin-right: 13px;

                    img {
                        width: 84px;
                        height: 84px;
                        border-radius: 10px;
                    }

                    .delete-content {
                        cursor: pointer;
                        position: absolute;
                        top: 3px;
                        left: 55px;
                        width: 26px;
                        height: 26px;
                        background-color: $white;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;

                        img {
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
            }
        }

        .footer-btn {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 10px 40px 0px 40px;

            .skip {
                display: flex;
                align-items: center;
                cursor: pointer;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: $primary-blue;
            }

            .next-button-content {
                display: flex;
                flex: 1;
                align-items: center;
                justify-content: flex-end;

                .back {
                    cursor: pointer;
                    margin-right: 23px;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    opacity: 0.5;
                }

                .button {
                    width: 100px;
                }
            }
        }

        .report-editor {
            cursor: pointer;
            display: flex;
            align-items: center;
            position: absolute;
            top: 69.5vh;
            left: 38px;

            .editor {
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: $light-blue;
                margin-left: 12px;
            }
        }
    }

    .assign-to {
        flex: 1;
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: center;

        .form {
            flex: 1;
            margin: 40px 0px 0px 40px;

            .row {
                display: flex;
                justify-content: flex-start;

                .item {
                    margin-right: 30px;
                }
            }
        }

        .footer-btn {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            margin: 10px 40px 0px 40px;

            .button {
                width: 150px;
            }

            .back {
                cursor: pointer;
                margin-right: 23px;
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                opacity: 0.5;
            }
        }

        .report-editor {
            cursor: pointer;
            display: flex;
            align-items: center;
            position: absolute;
            top: 69.5vh;
            left: 38px;

            .nav-link {
                display: flex;
                justify-content: center;
                align-items: center;
                text-decoration: none;
            }

            .editor {
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: $light-blue;
                margin-left: 12px;
            }
        }
    }

    @media (max-width: 768px) {
        .stepper {
            display: none !important;
        }

        .stepper-container {
            display: none !important;
        }
    }
}
