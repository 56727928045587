@import "../../helpers/Colours.scss";

.view-edit-doctor-container {
    margin: 0px 20px 0px 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .title {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 18px;
    }

    .input-field {
        width: 260px;
        margin-bottom: 12px;
    }

    .content {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;
    }

    .row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @media (max-width: 768px) {
            .input-field {
                width: 100%;
            }
        }

        .data {
            display: flex;
            flex-direction: column;
            width: 260px;
            .header {
                font-weight: 500;
                font-size: 10px;
                line-height: 12px;
                text-transform: uppercase;
                opacity: 0.5;
                margin-bottom: 10px;
            }

            .body {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
            }
        }
    }

    .view-data {
        background-color: $uploader-background;
        padding: 20px 32px 28px 32px;

        .details {
            max-height: 200px;
            height: 200px;
            overflow-y: auto;
            margin-top: 10px;

            .row-clinic {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 6px 0px;
                border-bottom: 1px solid $black_06;

                .name {
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                }
            }
        }

        .data-header {
            display: flex;
            align-items: center;

            .header {
                font-weight: 600;
                font-size: 14px;
                margin-right: 15px;
                line-height: 17px;
                cursor: pointer;
            }

            .selected-header {
                padding-bottom: 3px;
                border-bottom: 1px solid;
                opacity: 1;
            }

            .add {
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                color: $orange;
                cursor: pointer;
            }
        }

        .data {
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid $search-drp-bottom-brd;
            padding-bottom: 10px;
            padding-top: 12px;
            .name {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
            }

            .trash {
                cursor: pointer;
            }
        }
    }

    .footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 15px;

        .cancel-btn {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: $black_50;
            margin-right: 32px;
            cursor: pointer;
        }

        .button {
            width: 165px;
        }
    }
}

@media (max-width: 768px) {
    .modal-container .view-edit-doctor-card {
        margin: 0px 20px;
    }
}
