@import "../../helpers/Colours.scss";

.btn {
  font-weight: 600;
  font-size: 14px;
  height: 16px;
  cursor: pointer;
}

.doc-button-container {
  background-color: $orange;
  color: $white;
  padding: 0px 32px;
  border-radius: 10px;
  cursor: pointer;
  line-height: 17px;
  display: flex;
  border: 1px solid transparent;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}
