@import "../../helpers/Colours.scss";

.indicator-box {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .dot {
        padding-right: 10px;
        width: 10px;
        height: 10px;
    }

    .indicator {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0px 20px;

        .indicator-text {
            font-weight: 500;
            font-size: 12px;
            color: $indicator-text-colour;
        }

        .margin-right {
            margin-right: 30px;
        }
    }
}

@media (max-width: 1024px) {
    .indicator-box {
        .margin-right {
            margin-right: 10px !important;
        }
    }
}
