@import "../../helpers/Colours.scss";

.input-container {
    min-width: 110px;
    width: 100%;

    .text-field-base {
        .MuiInputBase-root {
            margin-top: 15px;
            border-bottom: 1px solid transparent !important;

            &:hover::before {
                border-bottom: 1px solid $black !important;
            }

            &::after {
                border-bottom: 1px solid $black !important;
                transition: none;
            }

            &::before {
                border-bottom: 1px solid $black_1 !important;
            }
        }

        .MuiFormLabel-root {
            font-weight: 600;
            font-size: 10px;
            color: $black !important;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
                "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
        }

        input {
            margin-bottom: 8px;
            font-weight: 600;
            font-size: 14px;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
                "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
        }
    }

    .text-field-error {

        // @include am-mui-text-field-error;
        .MuiInputBase-root {
            margin-top: 15px;
            border-bottom: 1px solid transparent !important;

            &:hover::before {
                border-bottom: 1px solid $red !important;
            }

            &::after {
                border-bottom: 1px solid $red !important;
                transition: none;
            }

            &::before {
                border-bottom: 1px solid $red !important;
            }
        }

        .MuiFormLabel-root {
            font-weight: 600;
            font-size: 10px;
            color: $red !important;
            font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
                "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
        }
    }

    .MuiAutocomplete-listbox {
        text-align: left;
        white-space: normal;
    }

    .MuiAutocomplete-option {
        margin: 0px 10px !important;
        border-radius: 8px;

        &:hover {
            background-color: $primary-blue !important;
            color: $white;
        }
    }
}