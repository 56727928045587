@import "../../helpers/Colours.scss";

.edit-report-type-container {
    margin: 0px 30px 0px 30px;

    .title {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 35px;
    }

    .input-row {
        height: 50px;
        margin-top: 10px;
    }

    .footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 40px;

        .cancel-btn {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: $black_50;
            margin-right: 32px;
            cursor: pointer;
        }

        .button {
            width: 180px;
        }
    }
}
