@import "../../helpers/Colours.scss";

.add-doctor-container {
    margin: 0px 20px 0px 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    .title {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 15px;
    }

    .input-field {
        width: 260px;
        margin-bottom: 16px;
    }

    .content {
        overflow-y: auto;
        flex: 1;
    }

    .row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @media (max-width: 768px) {
            .input-field {
                width: 100vw !important;
            }
        }
    }

    .clinic-name {
        display: flex;
        flex-direction: column;
        background-color: $uploader-background;
        padding: 20px 32px;
        height: 232px;
        flex: 1;

        .row-view {
            display: flex;

            .header {
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
                margin-bottom: 15px;
                margin-right: 15px;
                opacity: 0.5;
                cursor: pointer;
            }

            .selected-header {
                padding-bottom: 3px;
                border-bottom: 1px solid;
                opacity: 1;
            }
        }

        .row-data {
            max-height: 210px;
            height: 210px;
            overflow-y: auto;
            .row-clinic {
                display: flex;
                justify-content: space-between;
                padding: 10px 0px;
                border-bottom: 1px solid $black_06;
                .name {
                    display: flex;
                    align-items: center;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 17px;
                }
            }
        }
    }

    .footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 20px;

        .cancel-btn {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: $black_50;
            margin-right: 32px;
            cursor: pointer;
        }

        .button {
            width: 161px;
        }
    }
}

@media (max-width: 768px) {
    .modal-container .view-add-doctor-card {
        width: 85% !important;
    }
}
