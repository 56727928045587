@import "../../helpers/Colours.scss";

.file-upload-container {
    cursor: pointer;
    height: 152px;
    top: 220px;
    border: 1px dashed $uploader-border;
    background: $uploader-background;
    border-radius: 10px;

    &:hover {
        border: 1px solid $orange;
    }

    .heading {
        height: 27px;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
    }

    .sub-heading {
        font-weight: 400;
        font-size: 12px;
        opacity: 0.5;
        line-height: 15px;
    }

    .add-file {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        margin: 20px 0 0 0;
        position: relative;
        width: 200px;

        .label-input {
            cursor: pointer;
            color: $orange;
            font-size: 14px;
            font-weight: 600;
            display: flex;
        }

        .trash {
            margin-right: 10px;
            height: 20px;
            width: 20px;
        }
    }

    .edit-file {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin: 20px 0px 0px 0px;
        width: 200px;

        input {
            cursor: pointer;
            display: block;
            position: absolute;
            opacity: 0;
        }

        label {
            color: $orange;
            font-size: 14px;
            font-weight: 600;
        }

        .trash {
            margin-right: 10px;
            height: 20px;
            width: 20px;
        }
    }

    .action {
        width: 100px;
        height: 100px;
        margin: 0px 20px 0px 0px;
        border-radius: 50%;
    }
}

.upload-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.alignment {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.margin {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 0px 0 16px 0;
}

.edit-upload-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .sub-heading {
        width: 220px;
    }
}

.edit-upload-alignment {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}
