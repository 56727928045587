@import "../../helpers/Colours.scss";

.reports-container {
    flex: 1;
    margin-top: 35px;
    display: flex;
    flex-direction: column;

    .dot {
        padding-right: 10px;
        width: 10px;
        height: 10px;
    }

    .top-bar {
        display: flex;
        flex-direction: column;
        padding: 0px 40px;

        .text {
            font-weight: 500;
            font-size: 24px;
        }
    }

    .table-container {
        flex: 1;
    }

    .row {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .table {
        display: flex;
        flex-direction: column;
        padding-top: 40px;
        overflow-x: auto;
        height: calc(100vh - 310px);

        .large {
            flex: 1;
        }

        .medium {
            flex: 0.8;
        }

        .small {
            flex: 0.6;
        }

        .extra-small {
            flex: 0.1;
        }

        .justify {
            justify-content: space-between;
        }

        .justify-end {
            justify-content: end;
        }

        .text {
            font-weight: 500;
            font-size: 14px;
        }

        @media (max-width: 1440px) {
            .text {
                font-size: 12px;
            }
        }

        .header {
            border-bottom: groove;
            border-bottom-width: 1px;
            border-bottom-color: $border-colour;
            padding: 0px 0px 13px 0px !important;
            display: flex;

            .clickable {
                cursor: pointer;
            }

            .header-row {
                display: flex;
                align-items: center;

                .icon {
                    margin-left: 8px;
                    cursor: pointer;
                }

                .invert-image {
                    margin-left: 8px;
                    transform: rotate(180deg);
                }

                .image {
                    margin-left: 8px;
                }
            }

            .filter-icon {
                cursor: pointer;
                width: 18px;
                opacity: 0.3;
            }
        }

        .data {
            display: flex;
            flex-direction: column;
            overflow-y: auto;

            .pending-padding {
                padding: 10px 0px;
            }

            .submitted-padding {
                padding: 16px 0px;
            }

            .data-row {
                cursor: pointer;
                display: flex;
                flex-direction: row;
                color: $table-text-colour;
                border-bottom: groove;
                border-bottom-width: 1px;
                border-bottom-color: $border-colour;

                .icon {
                    cursor: pointer;
                }

                .space {
                    padding-left: 10px;
                }

                .cell {
                    display: flex;
                    align-items: center;

                    .date {
                        border-right: groove;
                        padding-right: 8px;
                        margin-right: 8px;
                        border-right-color: $border-colour;
                        border-right-width: 1px;
                    }

                    .dark-red {
                        background-color: $red;
                        color: $white;
                    }

                    .red {
                        background: $urgency-background-red;
                        color: $red;
                    }

                    .yellow {
                        background: $urgency-background-yellow;
                        color: $yellow;
                    }

                    .light-blue {
                        background: $urgency-background-light-blue;
                        color: $light-blue;
                    }

                    .dark-blue {
                        background: $urgency-background-dark-blue;
                        color: $dark-blue;
                    }

                    .urgency-box {
                        display: flex;
                        width: 100px;
                        height: 35px;
                        border-radius: 8px;
                        align-items: center;
                        justify-content: center;

                        .margin-right {
                            margin-right: 4px;
                        }

                        .urgency-icon {
                            width: 17px;
                            height: 17px;
                        }
                    }

                    .icon-box {
                        display: flex;
                        align-items: center;
                    }
                }
            }

            .pointer {
                cursor: pointer;
            }
        }
    }


    // Responsive Chnages - Start -------------------
    @media(max-width: 1024px) {
        .table {
            height: calc(100vh - 290px);
        }
    }

    @media (max-width: 425px) {
        .table {
            height: calc(100vh - 285px);
        }

        .top-bar .row {
            flex-direction: column;
            justify-content: start;
            align-items: start;

            .indicator-box {
                margin: 10px 0px;
                width: 100%;

                .indicator {
                    margin: 0px;
                    gap: 20px;
                }
            }

            .search-container {
                padding: 0px;
                width: 100%;
            }
        }

    }

    // Responsive Chnages - End -------------------

}