@import "../../helpers/Colours.scss";

.file-upload-ui {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .file-uploader {
        margin: 20px 40px 0px 40px;
    }

    .uploaded-files-area {
        display: flex;
        flex-direction: column;
        flex: 1 1;
        margin: 10px 40px 0px 40px;
        justify-content: space-between;
        overflow-y: auto;

        .file-doc {
            flex: 1;
            overflow-y: auto;

            .pending {
                color: $blue_02;
            }

            .uploading {
                color: $orange_02;
            }

            .file-content-view {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid $uploader-border;
                padding-bottom: 16px;
                padding-top: 16px;
                margin-right: 10px;

                .file-data {
                    display: flex;
                    align-items: center;

                    .file-name {
                        cursor: pointer;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 17px;
                        margin-left: 13.5px;
                    }
                }

                .file-properties {
                    display: flex;
                    align-items: center;

                    .img-content {
                        cursor: pointer;
                        margin-right: 10px;
                        margin-left: 40px;
                        width: 15px;
                    }

                    span {
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 15px;
                        opacity: 0.5;
                        margin-left: 40px;
                    }

                    img {
                        height: 18px;
                    }
                }
            }
        }

        .file-img {
            display: flex;
            margin-top: 14px;
            max-width: 10in;
            // flex: 1 0 0;
            overflow-x: auto;
            overflow-y: hidden;

            .img-uploaded {
                position: relative;
                margin-right: 13px;
                border: 1px solid $black_07;
                border-radius: 10px;

                .image-source {
                    width: 84px;
                    height: 84px;
                    border-radius: 10px;
                }

                .delete-content {
                    cursor: pointer;
                    position: absolute;
                    top: 3px;
                    left: 55px;
                    width: 26px;
                    height: 26px;
                    background-color: $white;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;

                    img {
                        width: 16px;
                        height: 16px;
                    }
                }
                .delete-content + .delete-content {
                    left: 24px;
                }
            }
        }
    }
}
