@import "../../helpers/Colours.scss";

.edit-staff-mem-container {
    margin: 0px 20px 0px 20px;

    .title {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 35px;
    }

    .input-field {
        width: 260px;
        margin-bottom: 20px;
    }

    .row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @media (max-width: 768px) {
            .input-field {
                width: 100%;
            }
        }
    }

    .footer {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-top: 40px;

        .cancel-btn {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: $black_50;
            margin-right: 32px;
            cursor: pointer;
        }

        .button {
            width: 165px;
        }
    }
}
