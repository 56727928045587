@import "../../helpers/Colours.scss";

.drp-search-container {
    display: flex;
    flex-direction: column;
    width: 306px;
    // height: 170px;
    justify-content: space-between;

    .search-container {
        background: $search-background;
        width: 305px;
        border-radius: 8px !important;
        padding: 0px !important;
        margin-bottom: 10px;

        input {
            height: 45px;
        }

        .img-container {
            margin: 0px !important;

            .img {
                margin: 14px 17px 13px 19px;
                width: 24px;
                height: 24px;
            }
        }

        .input {
            font-size: 14px;
            font-weight: 500;
            color: $search-text;
            padding: 0px !important;
        }
    }

    .item {
        cursor: pointer;
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        padding: 9px 49px 9px 10px;
        border-radius: 8px;

        &:hover {
            color: $blue-tag;
            background-color: $blue-hover;
            opacity: 1;
        }
    }

    ::-webkit-scrollbar {
        width: 5px;
        height: 5px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
        background: #f1f1f1;
        opacity: 0.5;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
        background: #888;
        opacity: 0.5;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
        background: #555;
        opacity: 0.5;
    }
}
