@import "../../helpers/Colours.scss";

.view-user-card-container {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0px 40px 0px 40px;

    .user-img {
        font-size: 32px;
        width: 100px;
        border-radius: 50px;
        height: 100px;
    }

    .user-role {
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: $user-role;
        margin-top: 15px;
    }

    .user-name {
        font-weight: 500;
        font-size: 20px;
        line-height: 24px;
        margin-top: 20px;
    }

    .user-email {
        font-weight: 500;
        font-size: 14px;
        line-height: 17px;
        margin-top: 8px;
        color: $black_50;
    }

    .user-data {
        display: flex;
        width: 70%;
        justify-content: center;
        margin-top: 20px;

        .data {
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: $black_50;

            span {
                color: $black;
            }
        }
    }
}

@media (max-width: 760px) {
    .modal-container .card-view-user {
        margin: 0px 20px;
    }
}
