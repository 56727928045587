@import "../../helpers/Colours.scss";

.notification-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 38px 40px;

    .header {
        display: flex;
        justify-content: flex-start;

        .title {
            font-weight: 500;
            font-size: 24px;
            line-height: 29px;
        }
    }

    .notification-body {
        flex: 1;
        margin-top: 32px;
        background: $hover;
        padding: 40px 60px;
        border-radius: 8px;
        min-width: 280px;

        .notification-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 32px;

            button {
                background-color: transparent;
                border: none;
                cursor: pointer;
                font-family: Inter, sans-serif;
                font-size: 14px;
                font-weight: 600;
                color: $orange;
                text-wrap: nowrap;
            }
        }

        .content {
            height: calc(100vh - 270px);
            overflow-y: auto;
            display: flex;
            flex-direction: column;

            .notification-content {
                display: flex;
                align-items: center;
                padding: 15px 40px;
                background: $white;
                border-radius: 8px;
                justify-content: space-between;
                margin-bottom: 10px;

                .data {
                    flex: 1;
                    display: flex;
                    align-items: center;

                    .profile-img {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                    }

                    .message {
                        display: flex;
                        font-weight: 500;
                        font-size: 16px;
                        line-height: 19px;
                        margin-left: 10px;
                        text-align: start;
                    }
                }

                .other-data {
                    display: flex;
                    align-items: center;

                    .action-btn-area{
                        display: flex;
                        flex-direction: row;
                        gap: 20px;
                        justify-content: end;
                        align-items: center;
                    }
                    .time {
                        width: 100px;
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        opacity: 0.4;
                    }

                    .view-report {
                        cursor: pointer;
                        display: flex;
                        justify-content: flex-end;
                        font-weight: 600;
                        font-size: 14px;
                        line-height: 17px;
                        color: $orange;
                        text-decoration: none;
                    }

                    .close-notification {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        cursor: pointer;
                        margin: 0px 0px 0px 30px;
                        width: 20px;
                        height: 20px;
                        padding: 2px;

                        .close {
                            width: 15px;
                            opacity: 0.8;
                        }

                        .close:hover {
                            width: 16px;
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

@media(max-width: 1200px) {
    .notification-container {
        .notification-body {
            padding: 30px 30px;
        }
    }
}


@media(max-width: 1024px) {
    .notification-container {
        .notification-body {
            padding: 20px !important;

            .content {
                height: calc(100vh - 215px);
            }
        }

        .notification-content {
            padding: 5px 10px !important;

            .profile-img {
                width: 25px !important;
            }

            .message {
                font-size: 14px !important;
            }

            .time {
                font-size: 12px !important;
            }

            .view-report {
                font-size: 12px !important;
            }

            .close-notification {
                margin-left: 4px !important;
            }
        }
    }
}

@media(max-width: 768px) {
    
    .notification-container .notification-body {
        .notification-header{
            margin-bottom: 20px;

            button{
                margin: 20px 0px 0px 0px;
            }
        }
        .content .notification-content {
            flex-direction: column-reverse;
            padding: 10px 15px !important;
    
            .data {
                width: 100%;
            }
    
            .other-data {
                width: 100%;
                justify-content: space-between;
                text-align: start;
            }
        }
    }
}

@media(max-width: 600px) {
    .notification-container {
        .notification-body {
            padding: 20px !important;
        }

        .notification-content {
            padding: 5px 10px !important;

            .message {
                font-size: 13px !important;
            }

            .time {
                font-size: 11px !important;
            }

            .view-report {
                font-size: 11px !important;
            }
        }
    }
}

@media(max-width: 425px) {
    .notification-container .notification-body .content {
        height: calc(100vh - 245px);

        .notification-content {
            .data {
                .profile-img {
                    display: none !important;
                }
                .message {
                    margin: 0px;
                }
            }
            .other-data{
                .view-report{
                    display: none;
                }
            }
        }
    }
}

