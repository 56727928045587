@import "../../helpers/Colours.scss";

.analytics-container {
    flex: 1;
    flex-direction: column;
    padding: 40px 20px 20px 20px;
    background-color: $element-background;
    max-width: 100%;
    overflow-x: auto;

    .top-bar {
        display: flex;
        align-items: center;
        flex: 1;

        .text {
            font-weight: 500;
            font-size: 24px;
        }
    }

    .top-header {
        display: flex;
        align-items: center;
        margin: 18px 0px 20px 0px;
        background: $box-analytics-background;
        border-radius: 16px;
        min-width: 1200px;

        .content {
            padding: 20px 20px 20px 30px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            flex: 1;
            border-right: 2px solid $blue-hover;

            .forms {
                display: flex;
                flex: 1;

                .input-drp {
                    margin-right: 2vw;
                }

                .date-input {
                    margin-right: 2vw;
                }
            }
        }

        .download-content {
            padding: 20px 20px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            cursor: pointer;

            .data {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin: 0px 14px;

                .download {
                    font-weight: 700;
                    font-size: 10px;
                    line-height: 12px;
                    opacity: 0.5;
                }

                .report {
                    font-weight: 500;
                    font-size: 18px;
                    line-height: 22px;
                }
            }
        }
    }

    .user-analytics {
        display: flex;
        flex-direction: column;
        min-width: 1200px;

        .title-user {
            display: flex;
            font-weight: 500;
            font-size: 16px;

            .name {
                margin-left: 5px;
                opacity: 0.5;
            }
        }

        .card-data {
            margin: 24px 0px 19px 0px;
            display: flex;
            justify-content: space-between;
        }

        .card-content {
            flex: 1;
            margin-right: 20px;
            padding: 21px 0px 21px 24px;
            display: flex;
            background-color: $white;
            border-radius: 16px;

            .details {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                margin-left: 14px;
                justify-content: center;

                .header {
                    text-transform: uppercase;
                    font-weight: 700;
                    font-size: 10px;
                    line-height: 12px;
                    opacity: 0.5;
                }

                .body {
                    font-weight: 500;
                    font-size: 20px;
                    line-height: 24px;
                    margin-top: 4px;
                }
            }
        }

        .card-content:last-child {
            margin-right: 0px !important;
        }

        .legend-header {
            display: flex;
            flex-direction: row;

            .indicator-box {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .dot {
                    padding-right: 10px;
                    width: 10px;
                    height: 10px;
                }

                .indicator {
                    display: flex;
                    flex-direction: row;
                    flex-wrap: wrap;
                    margin: 0px 20px;

                    .indicator-text {
                        font-weight: 500;
                        font-size: 12px;
                        color: $indicator-text-colour;
                    }

                    .margin-right {
                        margin-right: 30px;
                    }
                }
            }

            @media (max-width: 1024px) {
                .indicator-box {
                    .margin-right {
                        margin-right: 10px !important;
                    }
                }
            }
        }
    }

    .analytics-body {
        display: flex;
        flex-direction: row;
        height: 40vh;

        .graph {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            flex: 0.75;
            margin-right: 10px;
            border-radius: 16px;
            background-color: $white;
            box-shadow: 0px 2px 16px $black_02;
            padding: 16px 16px 10px 14px;
            height: inherit;

            .graph-header {
                display: flex;
                justify-content: space-between;

                .header {
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                }

                .time-duration {
                    cursor: pointer;

                    .duration {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        margin-right: 6px;
                    }
                }
            }
        }

        .user-date {
            flex: 0.25;
            display: flex;
            margin-left: 10px;
            border-radius: 16px;
            background-color: $white;
            box-shadow: 0px 2px 16px $black_02;
            padding: 16px 16px 10px 14px;
            height: inherit;

            .users-data {
                display: flex;
                flex: 1;
                flex-direction: column;

                .header {
                    display: flex;
                    font-weight: 600;
                    font-size: 14px;
                    line-height: 17px;
                    margin-bottom: 20px;
                }

                .user-table {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .img-user {
                        width: 115px;
                        height: 115px;
                        border-radius: 50%;
                        margin: 5px;
                    }

                    .role {
                        font-weight: 600;
                        font-size: 12px;
                        line-height: 15px;
                    }

                    .name {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        margin: 16px 0px 8px 0px;
                    }

                    .email {
                        font-weight: 400;
                        font-size: 12px;
                        line-height: 15px;
                        opacity: 0.4;
                    }

                    .last-login {
                        display: flex;
                        align-items: center;
                        flex: 1;
                        font-weight: 500;
                        font-size: 12px;
                        line-height: 15px;
                        gap: 5px;

                        span {
                            opacity: 0.5;
                        }
                    }

                    .member-time {
                        font-weight: 500;
                        font-size: 10px;
                        line-height: 12px;
                        margin-bottom: 10px;
                        opacity: 0.5;
                    }

                    .scriber {
                        color: $primary-blue;
                    }

                    .proofer {
                        color: $orange;
                    }

                    .reviewer {
                        color: $green;
                    }
                }

                .users-table {
                    display: flex;
                    flex-direction: column;
                    max-height: 34vh;
                    overflow-y: auto;

                    .user-row {
                        display: flex;
                        align-items: center;
                        margin-bottom: 10px;

                        .img-user {
                            border-radius: 50%;
                            width: 35px;
                            height: 35px;
                        }

                        .user-name {
                            font-weight: 500;
                            font-size: 14px;
                            line-height: 17px;
                            opacity: 0.6;
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }

    .table {
        display: flex;
        flex-direction: column;
        margin-top: 60px;
        min-width: 1200px;

        &.home-analytics-table {
            margin-bottom: 20px;
        }

        .header-text {
            font-weight: 500;
            font-size: 14px;
        }

        .text {
            font-weight: 500;
            font-size: 14px;
            line-height: 17px;
            opacity: 0.6;
        }

        .large {
            flex: 1;
        }

        .medium {
            flex: 0.6;
        }

        .small {
            flex: 0.4;
        }

        .justify {
            justify-content: space-between;
        }

        .header {
            border-bottom: groove;
            border-bottom-width: 1px;
            border-bottom-color: $table-border;
            // padding: 0px 60px 13px 60px;
            padding: 0px 0px 13px 0px;

            display: flex;

            .header-row {
                display: flex;
                align-items: center;

                .img {
                    margin-left: 8px;
                    cursor: pointer;
                }
            }
        }

        .data {
            display: flex;
            flex-direction: column;
            max-height: 25vh;
            overflow-y: auto;

            .data-row-analysis {
                display: flex;
                flex-direction: row;
                // margin: 0px 60px;
                padding: 17px 0px 15px 0px;
                border-bottom: groove;
                border-bottom-width: 1px;
                border-bottom-color: $table-border;

                .dot {
                    padding-right: 10px;
                    width: 7px;
                    height: 7px;
                }

                .cell {
                    display: flex;
                    align-items: center;
                }

                .flex-end {
                    justify-content: flex-end !important;
                }

                .action {
                    margin-left: 16px;
                    cursor: pointer;
                }
            }
        }
    }

    .custom-tooltip {
        background-color: $tool-tip-two-background;
        border: 1px solid $search-drp-bottom-brd;
        padding: 5px 14px 5px 10px;
        display: flex;
        align-items: center;

        .label-value {
            font-weight: 600;
            font-size: 12px;
            color: $white;
            margin-left: 5px;
            opacity: 0.7;
        }

        .label-role {
            margin-left: 5px;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            color: $white;
            opacity: 0.7;
        }
    }
}