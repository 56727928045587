@import "../../helpers/Colours.scss";


.file-progress-container {
    border-radius: 50px;
    display: flex;
    justify-content: center;
    font-weight: 500;
    align-items: center;
    border: 1px solid $primary-blue;
    background: $white;
    color: $primary-blue;
}

.user-img {
    cursor: auto;
    width: 45px;
    height: 45px;
    border-radius: 50% !important;
}