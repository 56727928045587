@import "../../helpers/Colours.scss";

.privacy-policy-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    margin: 20px 20px 20px 20px;

    .logo {
        width: 24vw;
        min-width: 80px;
    }

    .title-1 {
        text-align: left;
        font-family: "Century Gothic", sans-serif;
        font-size: 3vw;
        font-weight: 600;
    }

    .title-2 {
        font-family: "Century Gothic", sans-serif;
        font-size: 2.6vw;
        text-align: left;
        color: #4c4c4c;
        margin-top: 1.4vh;
        font-weight: 300;
    }

    .a-1 {
        font-family: "Century Gothic", sans-serif;
        font-size: 2.2vw;
        font-weight: 300;
        line-height: 2vh;
        text-align: left;
    }

    .a-2 {
        text-align: left;
        font-family: "Century Gothic", sans-serif;
        font-size: 2vw;
        font-weight: 300;
        line-height: 2vh;
    }

    .bold-6 {
        font-weight: 600 !important;
    }

    .bold-4 {
        font-weight: 400 !important;
    }

    .color-blue {
        color: #007ae8;
    }

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .header-data {
            display: flex;
            flex-direction: column;
            justify-content: center;

            .section {
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }
        }
    }

    .body {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
    }

    .margin-top-1 {
        margin-top: 0.5vh;
    }

    .margin-top-2 {
        margin-top: 1vh;
    }

    .margin-top-3 {
        margin-top: 1.5vh;
    }

    .margin-top-4 {
        margin-top: 2vh;
    }
}

@media (min-width: 768px) {
    .privacy-policy-content {
        margin: 20px 5vw 20px 5vw;

        .logo {
            width: 16vw;
        }

        .title-1 {
            font-size: 2.2vw;
        }

        .title-2 {
            font-size: 1.8vw;
        }

        .a-1 {
            font-size: 1.7vw;
            line-height: 3vh;
        }

        .a-2 {
            font-size: 1.6vw;
            line-height: 3vh;
        }

        .margin-top-1 {
            margin-top: 1vh;
        }

        .margin-top-2 {
            margin-top: 2vh;
        }

        .margin-top-3 {
            margin-top: 3vh;
        }

        .margin-top-4 {
            margin-top: 4vh;
        }
    }
}
@media (min-width: 1024px) {
    .privacy-policy-content {
        margin: 20px 10vw 20px 10vw;

        .logo {
            width: 14vw;
        }

        .title-1 {
            font-size: 2vw;
        }

        .title-2 {
            font-size: 1.8vw;
        }

        .a-1 {
            font-size: 1.3vw;
            line-height: 3.2vh;
        }

        .a-2 {
            font-size: 1.2vw;
            line-height: 3.2vh;
        }

        .margin-top-1 {
            margin-top: 1vh;
        }

        .margin-top-2 {
            margin-top: 2vh;
        }

        .margin-top-3 {
            margin-top: 3vh;
        }

        .margin-top-4 {
            margin-top: 4vh;
        }
    }
}
@media (min-width: 1280px) {
    .privacy-policy-content {
        margin: 20px 20vw 20px 20vw;

        .logo {
            width: 12vw;
        }

        .title-1 {
            font-size: 1.8vw;
        }

        .title-2 {
            font-size: 1.8vw;
        }

        .a-1 {
            font-size: 1.1vw;
            line-height: 3.4vh;
        }

        .a-2 {
            font-size: 1vw;
            line-height: 3.4vh;
        }

        .margin-top-1 {
            margin-top: 1vh;
        }

        .margin-top-2 {
            margin-top: 2vh;
        }

        .margin-top-3 {
            margin-top: 3vh;
        }

        .margin-top-4 {
            margin-top: 4vh;
        }
    }
}
