@import "../../helpers/Colours.scss";

.stepper-container {
    display: flex;

    .img {
        width: 50px;
        margin: 0px 10px;
        height: 100%;
    }

    .step-wrapper {
        display: flex;

        .step {
            display: flex;

            .active {
                background: $primary-blue;
                color: $white;
            }

            .inactive {
                background: $white;
                border: 1px solid $primary-blue;
                color: $primary-blue;
            }

            .filled {
                background: $not-assigned-border;
                border: 1px solid $primary-blue;
                color: $primary-blue;
            }

            .int {
                width: 50px;
                height: 50px;
                border-radius: 50px;
                display: flex;
                justify-content: center;
                align-items: center;

                font-size: 18px;
                font-weight: 500;
            }

            .text {
                display: flex;
                flex-direction: column;
                justify-content: center;
                margin-left: 12px;

                .step {
                    font-size: 14px;
                    font-weight: 500;
                    color: $search-text;
                }

                .description-active {
                    color: $primary-blue;
                }

                .description-inactive {
                    color: $black;
                }

                .description {
                    font-size: 14px;
                    font-weight: 500;
                }

                @media (max-width: 1024px) {
                    .description {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        width: 8vw;
                    }
                }
            }
        }
    }
}
