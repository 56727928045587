@import "../../helpers/Colours.scss";

.tabs-container {
    display: flex;
    overflow-x: auto;
    scrollbar-width: none;
    width: 100%;
    .tab {
        text-wrap: nowrap;
        min-width: fit-content;
        padding: 9px 20px;
        background: $inactive-tab-background;
        color: $inactive-tab-colour;
        border-radius: 8px;
        font-size: 14px;
        cursor: pointer;
        font-weight: 600;

        &.active {
            background: $black;
            color: $white;
        }
    }
}
.tabs-container:after {
    content: "";
    position: sticky;
    background: linear-gradient(270deg, rgb(255 255 255 / 90%) 0%, rgba(0, 0, 0, 0) 80%);
    padding: 0px 25px;
    right: 0px;
    z-index: 100;
    transform: translate(0%, 0%);
}
.notification-header .tabs-container:after {
    background: linear-gradient(270deg, $hover 0%, rgba(0, 0, 0, 0) 80%);
}

// Responsive Chnages - Start -------------------

@media (max-width: 425px) {
    .tab-selector {
        flex-direction: column !important;
        align-items: center !important;
    }
    .new-button-container {
        display: none;
    }
}

// Responsive Chnages - End -------------------
