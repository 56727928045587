@import "../../helpers/Colours.scss";

.view-doctor-container {
    display: flex;
    flex-direction: column;
    margin: 0px 20px 0px 20px;
    overflow-y: auto;

    .title {
        font-weight: 500;
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 35px;
    }

    .content {
        overflow-y: auto;
    }

    .row {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        @media (max-width: 768px) {
            .data {
                width: 50%;
            }
        }

        .data {
            display: flex;
            flex-direction: column;
            width: 260px;
            margin-bottom: 30px;

            .header {
                font-weight: 500;
                font-size: 10px;
                line-height: 12px;
                text-transform: uppercase;
                opacity: 0.5;
                margin-bottom: 10px;
            }

            .body {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
            }
        }
    }

    .view-data {
        background-color: $uploader-background;
        padding: 20px 32px 28px 32px;

        .details {
            max-height: 200px;
            height: 200px;
            overflow-y: auto;
            margin-top: 10px;
        }

        .header {
            font-weight: 600;
            font-size: 14px;
            margin-right: 15px;
            line-height: 17px;
            cursor: pointer;
        }

        .selected-header {
            padding-bottom: 3px;
            border-bottom: 1px solid;
            opacity: 1;
        }

        .data {
            border-bottom: 1px solid $search-drp-bottom-brd;
            padding-bottom: 10px;
            padding-top: 12px;
            .name {
                font-weight: 500;
                font-size: 14px;
                line-height: 17px;
                margin-right: 5px;
            }
        }
    }
}
.assign-view {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin-right: 40px;

    .content {
        flex: 1;
        .assign-content {
            padding: 16px 30px 0px 30px;
            border-radius: 8px;
            background-color: $black_05;
            margin-bottom: 16px;

            .header {
                font-weight: 600;
                font-size: 14px;
                line-height: 17px;
            }

            .assign-data {
                display: flex;
                padding-bottom: 16px;

                .data {
                    display: flex;
                    flex-direction: column;
                    width: 170px;
                    margin-right: 20px;
                    margin-top: 16px;

                    .assign-header {
                        text-transform: uppercase;
                        font-weight: 500;
                        font-size: 10px;
                        line-height: 12px;
                        opacity: 0.5;
                    }

                    .assign-data {
                        font-weight: 500;
                        font-size: 14px;
                        line-height: 17px;
                        margin-top: 5px;
                    }
                }
            }

            .to-assign-data {
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-bottom: 16px;

                .assign-mem {
                    width: 170px;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 17px;
                    opacity: 0.5;
                }

                .search-area {
                    // background-color: $whight_50;
                    padding: 5px;
                    height: 50px;
                    border-radius: 5px;

                    .input-dropdown-container .input-dropdown-container .MuiInput-root {
                        padding: 0px !important;
                    }
                }
            }
        }
    }

    .footer-btn {
        display: flex;
        justify-content: flex-end;

        .button {
            width: 150px;
        }
    }
}

@media (max-width: 768px) {
    .modal-container .view-view-doctor-card {
        margin: 0px 20px;
    }
}
