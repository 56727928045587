@import "../../helpers/Colours.scss";

.users-container {
    flex: 1;
    padding-top: 35px;

    .top-bar {
        display: flex;
        align-items: center;
        flex: 1;
        padding: 0px 40px;

        .text {
            font-weight: 500;
            font-size: 24px;
        }

        .elements {
            display: flex;
            flex: 1;
            justify-content: flex-end;
            align-items: center;
        }
    }

    .user-table-container{
        padding-top: 32px;

        .table {
            display: flex;
            flex-direction: column;
            overflow-x: auto;
            height: calc(100vh - 120px);

            .large {
                flex: 1;
            }
    
            .small {
                flex: 0.6;
            }
    
            .large-text {
                font-size: 14px;
                font-weight: 500;
            }
            @media (max-width: 1440px) {
                .large-text {
                    font-size: 12px;
                }
            }
    
            .small-text {
                font-size: 12px;
                font-weight: 500;
            }
            @media (max-width: 1440px) {
                .small-text {
                    font-size: 11px;
                }
            }
    
            .justify {
                justify-content: space-between;
            }
    
            .header {
                border-bottom: groove;
                border-bottom-width: 1px;
                border-bottom-color: $border-colour;
                padding: 0px 0px 13px 0px;
                display: flex;
                min-width: 800px;
    
    
                .header-row {
                    display: flex;
                    align-items: center;
    
                    .icon {
                        margin-left: 8px;
                        cursor: pointer;
                    }
                }
    
                .filter-cancel {
                    margin: auto 0 auto auto;
                }
    
                .filter-icon {
                    cursor: pointer;
                    width: 18px;
                    opacity: 0.3;
                }
            }
    
            .data {
                display: flex;
                flex-direction: column;
                overflow-y: auto !important;
                min-width: 800px;
    
                .cursor {
                    cursor: pointer;
                }
    
                .data-row {
                    display: flex;
                    flex-direction: row;
                    margin: 0px;
                    border-bottom: groove;
                    border-bottom-width: 1px;
                    border-bottom-color: $border-colour;
                    color: $table-text-colour;
    
                    .image-text {
                        display: flex;
                        flex-direction: row;
    
                        .img {
                            margin: 10px 14px 10px 0px;
                            width: 45px;
                            border-radius: 45px;
                            height: 45px;
                        }
    
                        .text {
                            display: flex;
                            flex-direction: column;
                            align-items: flex-start;
                            justify-content: center;
                        }
                    }
    
                    .dot {
                        padding-right: 10px;
                        width: 7px;
                        height: 7px;
                    }
    
                    .cell {
                        display: flex;
                        align-items: center;
                    }
    
                    .progress-details {
                        padding-bottom: 8px;
                        display: flex;
                        justify-content: space-between;
                    }
    
                    .action {
                        margin-left: 16px;
                        cursor: pointer;
                    }
    
                    .icon {
                        cursor: pointer;
                    }
    
                    .space {
                        padding-left: 10px;
                    }
                }
    
                .profile-size {
                    width: 45px;
                    height: 45px;
                    margin: 10px 14px 10px 0px;
                    font-size: 14px;
                }
            }
        }
    }

}

  // Responsive Chnages - Start -------------------
 
  @media(max-width: 1024px){
    .users-container .user-table-container .table{
        height: calc(100vh - 110px);
    }
}
  @media(max-width: 425px){
    .users-container .user-table-container .table{
        height: calc(100vh - 120px);
        .data .data-row .action-icon{
            display: none;
        }
    }
}

  // Responsive Chnages - End -------------------