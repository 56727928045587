@import "../../helpers/Colours.scss";

.add-user-container {
    padding: 0px 20px 0px 20px;
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    .title {
        font-size: 24px;
        font-weight: 500;
        margin-bottom: 32px;
    }

    .input-field {
        width: 260px;
        margin-top: 10px;
    }

    @media (max-width: 768px) {
        .input-field {
            width: 100%;
        }
    }

    .add-user-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        overflow-y: auto;

        .form-content {
            flex: 1;
            overflow-y: auto;
            overflow-x: hidden;

            .row {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
            }
        }
        .add-type-content {
            margin-top: 10px;

            .title {
                font-weight: 500;
                font-size: 10px;
                line-height: 12px;
                color: $black_50;
            }

            .content {
                margin-top: 10px;
            }
        }

        .password-content {
            margin-top: 25px;
            .title {
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: $black_50;
            }
            .row {
                display: flex;
                justify-content: space-between;
                margin-top: 10px;
            }
        }
    }

    .footer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 25px;

        .button {
            width: 170px;
        }

        .cancel-container {
            cursor: pointer;
            font-weight: 600;
            font-size: 14px;
            line-height: 17px;
            color: $black_50;
            margin-right: 30px;
        }
    }
}

@media (max-width: 760px) {
    .modal-container .add-user-card {
        margin: 0px 20px;
    }
    .modal-container .edit-user-card {
        margin: 0px 20px;
    }
}
